<template>
  <div id="hello">
    <img
      src="../assets/img/app/404.png"
      alt=""
      class="img-fluid"
    >
      <div>
        <p class="text">
          <i>The page you are looking for might have been <br>temporarily removed or is not available</i>
        </p>
      </div>
      <a href="/home"><button class=" newbtn"
      > Go back to Home</button> </a>
  </div>

 
</template>

<script>
export default {};
</script>
<style >
.newbtn{
  background-color: #ec2426;
  color: azure;
  width: 200px;
  height: 50px;
  border: 0px;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
.text {
  text-align: center;
  color: black;
  margin-top: -33px;
  font-size: 14px;
}
#hello {
  height: 100vh;
  margin: auto;
  padding: 23%;
  margin-top: -102px;
}
#hello img {
display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
</style>