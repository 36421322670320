<template>
  <div class="landingpage easter">
    <TopNav></TopNav>
    <Tops></Tops>
    <div class=" ">
      <div class="page landing">
        <div class="landing-header get-started">
          <slider
            class="d-none d-md-block"
            height="400px"
            :ease="0.5"
            :interval="8000"
            :speed="1000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item v-for="(i, index) in banners" :key="index" :style="i">
              <div
                v-if="
                  i.url.toLowerCase() != '/home' &&
                    i.url.toLowerCase() != '' &&
                    i.url.toLowerCase() != null
                "
                class="container-fluid get-started"
                :style="{
                  'background-image': `url('` + url + i.img_url + `')`,
                  height: '400px',
                  'background-position': 'center',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat',
                  position: 'relative',
                }"
                data-toggle="modal"
                width="100%"
                data-target="#store"
                @click="setCategoryRoute(i.url.toLowerCase())"
              ></div>
              <div
                v-else
                class="container-fluid get-started"
                :style="{
                  'background-image': `url('` + url + i.img_url + `')`,
                  height: '400px',
                  'background-position': 'center',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat',
                  width: '100% !important',
                  position: 'relative',
                }"
                data-toggle="modal"
                width="100%"
                data-target="#store"
              >
                <div class="banner-text">
                  <!-- <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3> -->
                  <!-- <p class="subtitle">Drinks, groceries, and more are available for delivery and pickup.</p> -->
                  <button
                    data-toggle="modal"
                    data-target="#store"
                    class="start-button margin"
                  >
                    Get Started <i class="fa fa-long-arrow-right ml-2"></i>
                  </button>
                </div>
              </div>
            </slider-item>
          </slider>

          <slider
            class="d-block d-md-none"
            :duration="10000"
            height="100px"
            :speed="8000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item v-for="(i, index) in banners" :key="index" :style="i">
              <div
                v-if="
                  i.url.toLowerCase() != '/home' &&
                    i.url.toLowerCase() != '' &&
                    i.url.toLowerCase() != null
                "
                class="container-fluid get-started"
                :style="{
                  'background-image': 'url(' + url + i.img_url + ')',
                  height: '100px',
                  'background-position': 'center',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat',
                  position: 'relative',
                }"
                data-toggle="modal"
                width="100%"
                data-target="#store"
                @click="setCategoryRoute(i.url.toLowerCase())"
              ></div>
              <div
                v-else
                class="container-fluid get-started"
                :style="{
                  'background-image': 'url(' + url + i.img_url + ')',
                  height: '100px',
                  'background-position': 'center',
                  'background-size': 'cover',
                  'background-repeat': 'no-repeat',
                  width: '100% !important',
                  position: 'relative',
                }"
                data-toggle="modal"
                width="100%"
                data-target="#store"
              >
                <div class="banner-text">
                  <!-- <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3> -->
                  <!-- <p class="subtitle">Drinks, groceries, and more are available for delivery and pickup.</p> -->
                  <button
                    data-toggle="modal"
                    data-target="#store"
                    class="start-button margin"
                  >
                    Get Started <i class="fa fa-long-arrow-right ml-2"></i>
                  </button>
                </div>
              </div>
            </slider-item>
          </slider>
        </div>

              <div class="product-group container bg-white my-5 ">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="promotions/Specials" style="color: white; " >Top Products</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="promotions/Specials" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2 "
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.top"
                v-bind:key="index"
                class="col-6 col-md-3 col-lg-2 p-1"
              >
                <Product :product="product"
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="index" />
              </slide>
            </carousel>
          </div>

          <div class="product-group container bg-white my-5 ">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/Beverages" style="color: white; " >Beverages</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Beverages" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2 "
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.beverages"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product"
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>
           <div class="product-group container bg-white my-5">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/water" style="color: white; " >Water</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/water" style="color: #ff0000;">View More ></a></h5>
            </div>

            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2"
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.water"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product"
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>


          <div class="product-group container bg-white my-5">
            <div class="row header" >
              <h5 class="col-6 title text-left mt-3"><a href="/category/Commodities" style="color: white; " >Commodities</a></h5>
              <h5 class="col-6 title view-more text-right mt-3"><a href="/category/Commodities" style="color: #ff0000;">View More ></a></h5>
            </div>
            <carousel
              class="row  mt-4 pb-2  px-md-2 pb-sm-2"
              :interval="10000"
              :speed="300"
              :scrollPerPage="false"
              :loop="true"
              :autoplay="true"
              :rewind='true'
              :paginationEnabled='false'
              :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
            >
              <slide
                v-for="(product, index) in products.commodities"
                v-bind:key="index"
                class="col-xs-6 col-sm-3 col-lg-2 p-1"
              >
                <Product :product="product" 
                v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="product.id" />
              </slide>
            </carousel>

          </div>


   
      </div>
      <storeSelector ref="store"></storeSelector>
    </div>

    <!--  <pageDescription />-->
    <Footer></Footer>
  </div>
</template>

<style>
.header{
  background-color: #000066;
  /* border-radius: 25% 25% 0 0; */
  border-radius: 8px 8px 0 0;
}

</style>

<script>
import StoreSelector from "@/components/StoreSelector.vue";
import TopNav from "@/components/TopNav.vue";
import Tops from '@/components/Tops.vue'
import Footer from "@/components/Footer.vue";
import Product from '@/components/Product.vue'
import ProductModal from '@/components/ProductModal.vue'
import pageDescription from "@/components/pageDescription.vue";
import * as $ from "jquery";

export default {
  name: "LandingPage",
  components: {
    StoreSelector,
    TopNav,
    Tops,
    Footer,
    Product,
    ProductModal ,
    pageDescription,
  },
  data() {
    return {
      loader: "",
      url: this.$request.url,
      banners: [],
       image_url: this.$request.url,
      showSearch: false,
      products: {},
      pro: '',
      viewproduct: false,
      loader: '',
      categories: [],
      promotions: [],
      banners: [],
    };
  },

  beforeMount() {
    
      
    this.$store.dispatch("setStoreStatus", false);
    this.$store.dispatch("ToggleShowSearch", false);
    this.loader = this.$loading.show();
    
         
     
  },
  mounted() {
      $("#store").modal("show");

    setTimeout(() => {
      this.loader.hide();
      if (this.$store.getters.categoryRoute != "/home") {
        $("#store").modal("show");
      }
    }, 2000);
    this.fetchBanners();
  },

  created() {
    // this.banners = this.$store.getters.banners
    // console.log(this.banners)
  },

  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        document.title =
          "Market Square | Online Shopping | Groceries & Household Appliances | Find More, Pay Less";
      },
    },
  },

  methods: {
    setCategoryRoute(route) {
      this.$store.dispatch("setCategoryRoute", route);
    },
    goToCategory(name) {
      switch (name) {
        case "Black Friday":
          return "/black-friday";
          break;
        case "First Image":
          return "/category/sda";
          break;
        case "Second Image":
          return "/category/medicare";
          break;
        case "Third Image":
          return "/category/spirits";
          break;
        case "landing page":
          return "/category/fresh produce";
          break;
        case "banner_5":
          return "/category/groceries";
          break;
        default:
          return null;
      }
    },
    goTo(name) {
      // let result = this.goToCategory(name);
      // if(result != null) {
      //   this.$router.push(result);
      if (name.toLowerCase().includes("xmas")) {
        this.$router.push("/hampers");
      }
    },
    fetchBanners() {
      let req = {
        what: "landingPageThumbnails",
        showLoader: false,
      };
      this.$request
        .makeGetRequest(req)
        .then((response) => {
          if (response.type == "landingPageThumbnails") {
            this.banners = response.data.data;
            // this.$store.dispatch('banners', response.data.data)
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
   fetchPromotions () {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getPromotions') {
              this.categories = response.data.data
              this.$store.dispatch('promotions', response.data.data.filter((val) => val != null))

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
    fetchProducts () {
      let req = {
        what: "products",
        showLoader: false,
        params: {
          storeid: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {
          if (res.type == 'products') {
            console.log(res.data.data)
            let cart = this.$store.getters.cart;
            this.loader.hide()
            if (res.data.data.top.length > 0) {
              res.data.data.top.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                })
              })
              res.data.data.beverages.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              res.data.data.water.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              res.data.data.commodities.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }

                })
              })
              this.products = res.data.data;
            } else {
              this.$swal(
                "Empty store",
                `This store currently has no products. Please choose another store`,
                "error"
              );
            }
          }

        })
        .catch(error => {

          console.log(error)
        });
    },
    viewProduct (product) {
      
      this.pro = product;
      this.viewproduct = true
    },
  
};
</script>
