<template>
  <div>

    <TopNav></TopNav>
    <Tops></Tops>

    <div class="">
      <div class="" style="background-color:white">
        <div class="banner cart-banner  content-desktop">
          <div class="container" >
            <h3 class="title">Product View </h3>
          </div>           
        </div>

        <div class="container" id="content-mobile">
          <div id ="topnav" class="row">
            <a href="/home">
              <img  style="    margin-bottom: -15px; margin-left: 14px;" src="../assets/img/left.png"/> &nbsp;
            </a>
            <h3 class="title" style=" color:white; width: 109%;margin-left: 39px; font-size: 14px;font-weight: bold;text-align: left;margin-top:-10px">
              Product View
            </h3>
          </div>
        </div>

        <!-- <div id="hide_container"  style="padding:40vh 0"></div>-->

        <div id="content-mobile" class="product-group  container bg-white  my-5">
          <div id = "boxx" >
            <p v-if="product.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
            </p>
            <img
              v-if="product.img_url.includes('https://cdn.marketsquareng.website')"
              :src="product.img_url"
              alt=""
              class="img-fluid img-responsive"
            >
            <img
              v-else
              :src="'https://admin.sundrymarkets.com'+product.img_url"
              alt=""
              class="img-fluid img-responsive"
            >
          </div>

          <div class="product-text" style="font-weight: bold;">
            <p class="name" style="margin-left:20px;">{{product.name}}</p>
            <!-- <p class="sku">({{product.sku}})</p>-->
            <p v-if="product.name && (product.name.includes('/KG') || product.name.includes('/ KG'))" class="weight">
              (Per Kg)
            </p>
          </div>

          <div class="product-cat d-flex" style="margin-left:12px;">
            <span class="badge text-lowercase">{{product.category}}</span>
          </div>

          <p class="price" style="color:#000066;font-weight:bold; margin-left:20px;" v-if="$store.getters.isStoreSet!=false">
            <span v-if="product.promo">
              <span style="color:#ccc;font-size:13px;"><s>₦{{ formatPrice(product.sellingprice) }}</s></span> <br>
              <span>₦{{ formatPrice(Math.round((product.promo.value_percent/100)*product.sellingprice)) }}</span>
            </span>
            <span v-else-if="product.old_price">
              <span style="color:#ccc;font-size:12px;"><s>₦{{ formatPrice(product.old_price) }}</s></span> <br>
              <span>₦{{ formatPrice(product.sellingprice) }}</span>
            </span>
            <span v-else  class="price"> ₦{{ formatPrice(product.sellingprice) }}</span>
          </p>
        </div>

        <div id="content-mobile" class="box">
          <div class="description" style="font-weight: bold; color: black;margin-left: 25px;margin-top: 23px;">
            <p style="font-size:14px">Product Description </p>
            <p style="font-size:11px"> {{product.description}}</p>
          </div>
        </div>

        <div id="content-mobile">
        
            <button
                  :id="'btntp_modal'"
                class="btn add"
                  v-bind:class="product.hidebtn? 'hideqty':''"
                  v-if="$store.getters.isStoreSet!=false"
                    @click="addToCart(product, 'addtp_modal' ,'btntp_modal' ,'tp_modal')"
                >
                  <img
                    src="../assets/img/cart.png"
                    class="img1"
                    alt=""
                  >
                  <img
                    class="d-none img2"
                    src="../assets/img/cart-white.png"
                    alt=""
                  >

                  <span>Add to cart</span>
                </button>
                <button
                    class="addtocart mt-4"
                    v-else
                    data-toggle="modal"
                    data-target="#store"
                >
                  <img
                      src="../assets/img/cart.png"
                      class="img1"
                      alt=""
                  >
                  <img
                      class="d-none img2"
                      src="../assets/img/cart-white.png"
                      alt=""
                  >

                  <span>Select Store</span>
                  </button>
                
        
        <button
                  style=" height:35px;  justify-content: center;margin-left: 59px;border-radius: 2px;width: 226px;"
                  :id="'addtp_modal'"
                  class="addquantity mt-4 addm "
                  v-bind:class="product.hideqty? 'hideqty':''"
                  >
                  <div
                    @click="decreaseQuantity('tp_modal', product.id)"
                    class=" decrease"
                    >-</div>
                    <input
                    v-if="product.description.includes('/KG') || product.description.includes('/ KG')"
                    oninput="validity.valid||(value='');"
                    id='tp_modal'
                    type="number"
                    min="0.001"
                    step="any"
                    class="number"
                    :value=product.cart_qty
                    @keypress="restrictChars($event)"
                    @change="inputChange('tp_modal', product.id)"
                    >

                    <input
                    v-else
                    id='tp_modal'
                    type="number"
                    min="0"
                    step="1"
                    class="number"
                    :value=product.cart_qty
                    @keypress="restrictChars($event)"
                    @change="inputChange('tp_modal', product.id)"
                    />

                    <div
                    @click="increaseQuantity('tp_modal', product.id)"
                    class=" increase"
                    >+</div>
                </button>

        
        </div>

        <div id="content-mobile" class="product-group container bg-white my-5 ">
          <div class="row header" >
            <h5 class="col-6 title text-left mt-3" style="color: black; font-family: harabara ">Similar Products</h5>            
          </div>

          <carousel
            class="row  mt-4 pb-2  px-md-2 pb-sm-2 "
            :interval="10000"
            :speed="300"
            :scrollPerPage="false"
            :loop="true"
            :autoplay="true"
            :rewind='true'
            :paginationEnabled='false'
            :perPageCustom="[[320, 2],[375, 2],[425, 2],[768, 4], [1024, 6]]"
          >
            <slide
              v-for="(product, index) in products.top"
              v-bind:key="index"
              class="col-6 col-md-3 col-lg-2 p-1"
            >
              <Product :product="product"
              v-bind:key="product.sku"  @viewProduct="viewProduct($event)" :index="index" />
            </slide>
          </carousel>      
        </div>

          <!--mobile
            <div id="content-mobile">
                <button
                      :id="'btntp_modal'"
                    class="btn add"
                      v-bind:class="product.hidebtn? 'hideqty':''"
                      v-if="$store.getters.isStoreSet!=false"
                       @click="addToCart(product, 'addtp_modal' ,'btntp_modal' ,'tp_modal')"
                    >
                      <img
                        src="../assets/img/cart.png"
                        class="img1"
                        alt=""
                      >
                      <img
                        class="d-none img2"
                        src="../assets/img/cart-white.png"
                        alt=""
                      >

                      <span>Add to cart</span>
                    </button>
                    <button
                        class="addtocart mt-4"
                        v-else
                        data-toggle="modal"
                        data-target="#store"
                    >
                      <img
                          src="../assets/img/cart.png"
                          class="img1"
                          alt=""
                      >
                      <img
                          class="d-none img2"
                          src="../assets/img/cart-white.png"
                          alt=""
                      >

                      <span>Select Store</span>
                     </button>
                    
            </div> -->
         
        <div class="content-desktop">
          <div class="content container" id="show_container" style="display:none">
            <div v-if="Object.keys(product).length !== 0" id="product" class="card mt-5 mb-3 content-desktop">
              <div class="card-body">
                <div class="row mt-3">
                  <div class="col-sm-5 p-0">
                    <p v-if="product.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                        <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
                    </p>
                    <img
                      v-if="product.img_url.includes('https://cdn.marketsquareng.website')"
                      :src="product.img_url"
                      alt=""
                      class="img-fluid img-responsive"
                    >
                    <img
                      v-else
                      :src="'https://admin.sundrymarkets.com'+product.img_url"
                      alt=""
                      class="img-fluid img-responsive"
                    >
                  </div>

                  <div class="col-sm-7">
                    <div class="product-text">
                      <p class="name">{{product.name}}</p>
                      <p class="sku">({{product.sku}})</p>
                      <p
                        v-if="product.name && (product.name.includes('/KG') || product.name.includes('/ KG'))"
                        class="weight"
                      >(Per Kg)</p>
                    </div>

                    <div class="product-cat d-flex">
                      <span class="badge text-lowercase">{{product.category}}</span>
                    </div>

                    <div class="product-footer">
                      <p v-if="product.isBlackfriday == 1"> 
                        <span style="background:#000; color:#fff; font-size:12px; border-radius:3px; padding:3px 9px">Black Friday Deal</span> 
                      </p>

                      <p  v-if="product.active=='1'" class="availability in-stock">
                        <span><i class="fa fa-check-square-o" style="font-size: 13px;"></i> &nbsp;In Stock</span>
                      </p>

                      <p v-else class="availability out-stock">
                        <span v-if="$store.getters.isStoreSet!=false">
                          <i class="fa fa-check-square-o" style="font-size: 13px;"></i> &nbsp;Out of Stock
                        </span>
                      </p>

                      <p class="price" v-if="$store.getters.isStoreSet!=false">
                        <span v-if="product.promo">
                          <span style="color:#ccc;font-size:13px;"><s>₦{{ formatPrice(product.sellingprice) }}</s></span> <br>
                          <span>₦{{ formatPrice(Math.round((product.promo.value_percent/100)*product.sellingprice)) }}</span>
                        </span>
                        <span v-else-if="product.old_price">
                          <span style="color:#ccc;font-size:12px;"><s>₦{{ formatPrice(product.old_price) }}</s></span> <br>
                          <span>₦{{ formatPrice(product.sellingprice) }}</span>
                        </span>
                        <span
                          v-else
                          class="price"
                        > ₦{{ formatPrice(product.sellingprice) }}</span>
                          <!--content-desktop-->
                      </p>

                      <button data-toggle="modal" data-target="#foodproduct">
                        <span>Select Variation</span>
                      </button>                  
                    </div>

                    <!-- variation  Modal -->
                    <div class="modal fade" id="foodproduct" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title">Select Variation</h5>
                            <button
                              type="button"
                              class="close"
                              data-dismiss="modal"
                              aria-label="Close"
                            >
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          
                          <div class="modal-body"></div>
                        </div>
                      </div>
                    </div>
                  </div>                
                </div>
              </div>
            </div>

            <div v-if='!isEmpty' class="card mb-5 content-desktop">
              <div class="card-body">
                <div class="description">
                  <h5><u>Product Description</u> </h5>
                  <p> {{product.description}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Cart v-if='$store.getters.isStoreSet!=false' :products="[product]" />
    <storeSelector></storeSelector>
    <Footer id="scrollp"></Footer>
  </div>
</template>


<script>
import TopNav from '@/components/TopNav.vue'
import Tops from '@/components/Tops.vue'
import Footer from '@/components/Footer.vue'
import StoreSelector from '@/components/StoreSelector.vue'
import Cart from '@/components/CartComponent.vue'
import Product from '@/components/Product.vue'
import ProductModal from '@/components/ProductModal.vue'
import PreparedFoodModal from '@/components/PreparedFoodModal.vue'
import * as $ from "jquery";
export default {
  name: 'FoodProductView',
  components: {
    TopNav, Footer,Product, PreparedFoodModal, ProductModal, StoreSelector, Cart,Tops
  },
  data () {
    return {
      
      product: {
        name: this.$route.params.name,
        description:'',
        cart_qty: 0,
        category: this.$route.params.category,
        hidebtn: true,
        hideqty: true,
        quantity: 0,
        sellingprice: 0,
        newprice: 0,
        img_url: '',
        active: 0,
       },

      products: {
         top:[],
      
      }
    }
  },
  beforeMount () {
    this.$store.dispatch('ToggleShowSearch', true);
    this.loader = this.$loading.show();
  },
  computed : {
    isEmpty() {
      return Object.values(this.product).length == 0
    }
  },
  mounted() {
          
   window.addEventListener("scroll", this.onScroll, true)
   // $('html, body').animate({scrollTop: $("#end").offset().top}, 1000);

    this.fetchProducts();
    this.loader.hide();
    if(this.$store.getters.isStoreSet == false) {
      $("#store").modal('show');
      $(".close.float-right").hide();        
    } else {
        this.getProduct()
    }
  },
  

  watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = this.$route.params.name + " | Online Shopping | Market Square";
        }
    },
  },
  methods: {
     onScroll(e) {
          
          this.windowTop = Number(window.top.scrollY);
          this.hT = Number(document.querySelector('#scrollp').offsetTop)- Number(window.innerHeight)+ 200;       
          console.log(this.windowTop);
          console.log(this.hT);
            if(this.windowTop >= this.hT){
             document.querySelector('#btntp_modal').style.position = 'relative'
           
            }else{
               document.querySelector('#btntp_modal').style.position = 'relative'
            }
          
      },
     

    getProduct () {
      let req = {
        what: "getproduct",
        showLoader: false,
        params: {
          category: this.$route.params.category,
          storeid: this.$store.getters.store.id,
          name: this.$route.params.name
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {

          if (res.type == 'getproduct') {
            console.log(res.data.data)
            let pro = res.data.data;

            if(pro != null) {

                let cart = this.$store.getters.cart;

                pro.hidebtn = false;
                pro.hideqty = true;
                pro.cart_qty = pro.description.includes('/KG') || pro.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (pro.id == j.product.id) {
                    pro.hidebtn = true;
                    pro.hideqty = false;
                    pro.cart_qty = j.quantity;
                  }

                })
                this.product = pro;
                
                $("#hide_container").hide();
                $("#show_container").show();
            } else {
              this.$swal.fire('', `Product ${decodeURI(this.$route.params.name)} Was NoT Found under Category: ${this.$route.params.category} in ${this.$store.getters.store.name} Store`, "error" );
              setTimeout(function(){ location.href = "./"; }, 3000);
            }
            this.loader.hide()
          }

        })
        .catch(error => {
          this.$swal.fire('', `Product ${decodeURI(this.$route.params.name)} Was NoT Found under Category: ${this.$route.params.category} in ${this.$store.getters.store.name} Store`, "error" );
          setTimeout(function(){ location.href = "./"; }, 3000);
        });
    },
    fetchCategories () {
      let req = {
        what: "getCategories",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getCategories') {
              this.categories = response.data.data
              this.$store.dispatch('categories', response.data.data)

            }
          })
          .catch(error => {

            console.log(error)
          });
    },
     
    fetchPromotions () {
      let req = {
        what: "getPromotions",
        showLoader: false,
        params: {
          store_id: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)
          .then(response => {

            if (response.type == 'getPromotions') {
              this.categories = response.data.data
              this.$store.dispatch('promotions', response.data.data.filter((val) => val != null))

            }
          })
          .catch(error => {

            console.log(error)
          });
    },

     fetchProducts () {
      let req = {
        what: "products",
        showLoader: false,
        params: {
          storeid: this.$store.getters.store.id
        }
      }
      this.$request.makeGetRequest(req)

      

        .then(res => {
           this.products = res.data.data;
          if (res.type == 'products') {
            console.log(res.data.data)
            
            let cart = this.$store.getters.cart;
            this.loader.hide()
            if (res.data.data.top.length > 0) {
              res.data.data.top.forEach(i => {
                i.hidebtn = false;
                i.hideqty = true;
                i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
                cart.forEach(j => {
                  if (i.id == j.product.id) {
                    i.hidebtn = true;
                    i.hideqty = false;
                    i.cart_qty = j.quantity;
                  }
                })
              })
             
              this.products = res.data.data;
            } else {
              this.$swal(
                "Empty store",
                `This store currently has no products. Please choose another store`,
                "error"
              );
            }
          }

         
        })
        .catch(error => {

          console.log(error)
        });
    },
    viewProduct (foodproduct) {
      
      this.pro = foodproduct;
      this.viewproduct = true
    },
  

    addToCart (product, addid, addbtn, id) {
      document.getElementById(addid).classList.remove('hideqty');
      document.getElementById(addbtn).classList.add('hideqty')
      // this.loading = true;
      if (product.promo) {
        product["newprice"] = Math.round(
          (product.promo.value_percent / 100) * product.sellingprice
        );
      } else {
        product["newprice"] = product.sellingprice;
      }
      let cart = {
        product: {}
      }
      cart.quantity = document.getElementById(id).value;
      cart.unit_price = product.newprice;
      cart.price = product.newprice * cart.quantity;
      cart.product.id = product.id;
      cart.product.name = product.name;
      cart.product.price = product.newprice;
      cart.product.img_url = product.img_url;
      let cart_array = this.$store.getters.cart;
      let check = [];
      // cart_array.push(cart
      if (cart_array.length > 0) {
        cart_array.forEach(i => {
          if (i.product.id == cart.product.id) {
            check.push(1);
            i.quantity = parseInt(i.quantity) + 1;
            i.price += Number(i.unit_price);
            document.getElementById(id).value = i.quantity;
          }
          else {
            check.push(0)
          }
        })
        // console.log(check)
        if (!check.includes(1)) {
          cart_array.push(cart)
        }
      }
      else {
        cart_array.push(cart)
      }
      this.$store.dispatch('addToCart', cart_array)
    },
    removeFromCart (id) {
      let index;
      let cart = this.$store.getters.cart;
      cart.forEach((i, ind) => {
        if (i.product.id == id) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addToCart', cart)
    },
    inputChange (id, product_id) {
      var value = document.getElementById(id).value;
      if (value == 0) {
        document.getElementById('add' + id).classList.add('hideqty');
        document.getElementById('btn' + id).classList.remove('hideqty');
        this.removeFromCart(product_id);
      }
      else if (Math.sign(value) == '-1') {
        value = 1;
        document.getElementById(id).value = 1;
      }
      else {

      }
      this.updateCartQuantity(value, product_id, "input");

    },
    increaseQuantity (id, product_id) {
      var value = document.getElementById(id).value
      var v = Number(value) + 1;
      document.getElementById(id).value = v;
      this.updateCartQuantity(v, product_id, "+");

    },
    decreaseQuantity (id, product_id) {
      var value = document.getElementById(id).value;

      if (value != 0) {
        value = Number(value) - 1;
        if (value == 0) {
          document.getElementById('add' + id).classList.add('hideqty');
          document.getElementById('btn' + id).classList.remove('hideqty')
          // document.getElementById(id).value = 1;
          this.removeFromCart(product_id)
        }
        else {
          document.getElementById(id).value = value;
        }
      }
      else {
        value = 1;
        document.getElementById(id).value = value;
      }
      this.updateCartQuantity(value, product_id, "-");

    },
    updateCartQuantity (value, product_id, action) {
      // console.log(value)
      // console.log(action)
      let cart_array = this.$store.getters.cart;
      cart_array.forEach(i => {
        if (product_id == i.product.id) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;

          }
        }
      })
      this.$store.dispatch('addToCart', cart_array)
    },
    restrictChars: function ($event) {
      if ($event.key !== '-' || $event.code !== 'Minus') {
        return true
      } else {
        $event.preventDefault();
      }
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
  }
}

</script>

<style scoped>
#product .product-text .name {
  font-size: 0.96rem;
}
#product .product-text .sku {
  font-size: 13px;
  color: #9e9e9e;
}
#product .price {
  font-size: 23px;
  color: #f44336;
}
.description p {
  font-size: 13px;
}
#product .product-footer {
  margin-top: 15px;
}
#product img {
  max-height: 344px;
  text-align: center;
}
.card {
  border: 5px solid #ebebeb;
  border-radius: 0;
}
.availability.in-stock {
  padding: 3px 0;
  width: 100px;
}
.availability.out-stock {
  padding: 3px 0;
  width: 100px;
}
.availability.in-stock span {
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  display: block;
  background: #6dbe14;
  text-transform: uppercase;
  padding: 6px 8px;
  font-weight: 700;
  border-radius: 3px;
}

.availability.out-stock span {
  color: #fff;
  font-size: 11px;
  line-height: 16px;
  display: block;
  background: #ea1414;
  text-transform: uppercase;
  padding: 6px 8px;
  font-weight: 700;
  border-radius: 3px;
}
#continue {
  position: absolute;
  top: 0;
  right: 0;
  width: 162px;
}
.content-desktop {display: block;}
#content-mobile {display: none !important;}

@media screen and (max-width: 700px) {

.content-desktop {display: none;}
#content-mobile {display: block !important;;}

}
#boxx img{
     display: block !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 26px !important;
    /*width: 107px !important;*/
    height: 167px;
  
}
.box{
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 0px;
  padding: 1px;
  height: 124px;
  background-color: white;
  margin-top: -32px;
  color: rgb(161, 143, 143);
margin-left:20px;
  width: 313px;
}
.add{

    width: 226px;
    position:fixed;
    top:73%;
    margin-left: 22%;
   

}
.addm{

    width: 226px;
   
    
    margin-left: 22%;
   

}
#boxx {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 224px;
  background-color: white;
  margin-top: -32px;
  color: rgb(161, 143, 143);

  width: 313px;
}
</style>