<template>
  <div id="pageDescription">
    <div class="container">
      <div class="jumbotron" style="border:2px solid #f2f2f2; background:#fff;">
        <div class="">
          <p>Product Articles and keywords</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pageDescription',
}
</script>

<style>

</style>