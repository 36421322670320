<template>
  <div class="footer">
    <footer class="container">
      <div class="row">
        <div class="col-sm-12 mt-5">
          <div class="row container">
            <div class="col-sm-4 content-desktop">
              <h5 class="footer-heading my-2">Company</h5>
              <ul class="">
                <li class="footer-link">
                  <a href="/about">About Marketsquare</a>
                </li>
                <!-- <li class="footer-link"><a href="">Press & Media</a></li> -->
              </ul>
            </div>
            <div class="col-sm-4 content-desktop">
              <h5 class="footer-heading my-2">Product</h5>
              <ul class="">
                <li class="footer-link">
                  <a href="/giftcard">
                    Gift card
                  </a>
                </li>

              </ul>
            </div>
            <div class="col-sm-4 content-desktop">
              <h5 class="footer-heading my-2">Get in touch</h5>
              <ul class="">
                <li class="footer-link d-flex justify-content-start">
                  <a
                    class="mr-3"
                    href="https://twitter.com/marketsquarengr"
                  >
                    <img
                      src="../assets/img/Path 355.svg"
                      alt=""
                    >
                  </a>
                  <a
                    class="mr-3"
                    href="https://facebook.com/marketsquarengr"
                  > <img
                      src="../assets/img/Group 277.svg"
                      alt=""
                    ></a>
                  <a
                    class="mr-3"
                    href="https://instagram.com/marketsquarengr"
                  > <img
                      src="../assets/img/Group 275.svg"
                      alt=""
                    ></a>
                </li>
                <li class="footer-link">
                  <a href="mailto:service@sundrymarkets.ng">service@sundrymarkets.ng</a>
                </li>
                <li class="footer-link">
                  Market Square, Nigeria
                </li>

              </ul>
            </div>
            <hr class=" content-desktop footer-line col-sm-12">
          </div>
        </div>

        <div class=" col-sm-12 footer-img text-center">

          <img
            src="../assets/img/Group 7746.svg"
            alt=""
            class="img-responsive"
          >
        </div>
        <div class=" col-sm-12 content-desktop">
          <div class="container">
            <ul class="d-flex justify-content-center">
              <li class="footer-link">
                <a href="/terms">
                  Terms of Service
                </a>
              </li>
              <li class="footer-link">|</li>
              <li class="footer-link">
                <a href="/privacy">
                  Privacy Policy
                </a>
              </li>

            </ul>

          </div>
        </div>
        <div class=" col-sm-12 mt-3">
          <div class="container" id="content-mobile">
            <p class="footer-text">The site is owned and operated by Sundry Markets Limited – owners of Marketsquare - a company registered in Nigeria whose registered office is 23 Nzimiro Street, Old GRA, Port Harcourt, Rivers State, Nigeria. Company Registration No. 1181249, TIN No. 17810525 © {{(new Date()).getFullYear()}} marketsquareng.com All Rights Reserved.</p>

          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'Footer',

}
</script>


<style scoped>
@media screen and (max-width: 768px) {

.content-desktop {display: none;}
#content-mobile {display: block;}

}
</style>
