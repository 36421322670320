var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopNav'),_c('Tops'),_c('div',{staticClass:"account page bg-white py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('NewSidebar',{attrs:{"opt_active":_vm.opt_active}}),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"content"},[_c('h4',{staticClass:"title text-left"},[_vm._v("My Account Settings")]),_c('div',{staticClass:"col-sm-9 mt-3 p-0"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateUser)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.firstname),expression:"user.firstname"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" "},domProps:{"value":(_vm.user.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "firstname", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("First Name")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.lastname),expression:"user.lastname"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" "},domProps:{"value":(_vm.user.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "lastname", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Last Name")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.email),expression:"user.email"}],staticClass:"form-control",attrs:{"disabled":"","type":"email","placeholder":" "},domProps:{"value":(_vm.user.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "email", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Email Address")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"disabled":""},model:{value:(_vm.user.phone),callback:function ($$v) {_vm.$set(_vm.user, "phone", $$v)},expression:"user.phone"}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:"msq-button mt-4"},[_vm._v("Update Profile")]),_c('div',{staticClass:"form-group form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.subscription),expression:"user.subscription"}],staticClass:"form-check-input",attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.user.subscription)?_vm._i(_vm.user.subscription,null)>-1:(_vm.user.subscription)},on:{"change":function($event){var $$a=_vm.user.subscription,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.user, "subscription", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.user, "subscription", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.user, "subscription", $$c)}}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"exampleCheck1"}},[_vm._v("Receive Marketsquare promotional notification")])])])]}}])})],1),_c('div',{staticClass:"avatar col-3"})])])],1)]),_c('storeSelector'),_vm._m(0)],1),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade",attrs:{"id":"change","tabindex":"-1","role":"dialog","aria-labelledby":"exampleModalLabel","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog modal-sm"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title text-left",staticStyle:{"font-size":"15px","font-weignt":"600"}},[_vm._v("Change Password "),_c('br'),_c('small',{staticStyle:{"font-size":"11px","color":"#859BBCF5"}},[_vm._v("Change your account password below")])]),_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('form',{attrs:{"action":""}},[_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"password","placeholder":" "}}),_c('label',{staticClass:"anim"},[_vm._v("Current Password")])]),_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"password","placeholder":" "}}),_c('label',{staticClass:"anim"},[_vm._v("New Password")])]),_c('div',{staticClass:"form-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"password","placeholder":" "}}),_c('label',{staticClass:"anim"},[_vm._v("Confirm New Password")])]),_c('button',{staticClass:"msq-button mt-4"},[_vm._v("Update Password")])])])])])])}]

export { render, staticRenderFns }