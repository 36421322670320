var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopNav'),_c('Tops'),_c('div',{staticClass:"contact page"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('div',{staticClass:"container py-5"},[_vm._m(1),_c('hr',{staticClass:"mb-1"}),_c('div',{staticClass:"text-center mt-5 mb-3 h5"},[_vm._v("Contact Details")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendMessage)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.name),expression:"contact.name"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" ","required":""},domProps:{"value":(_vm.contact.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "name", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Full Name")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-6"},[_c('validation-provider',{attrs:{"rules":"required:length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{attrs:{"inputClasses":"input","wrapperClasses":"input"},model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-6"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":" "},domProps:{"value":(_vm.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Email Address")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selected),expression:"selected"}],staticClass:"form-control ",attrs:{"name":"","id":"","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selected=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"","selected":"","hidden":""}},[_vm._v("Select State")]),_vm._l((_vm.states),function(row,index){return _c('option',{key:index,domProps:{"value":row.state.name}},[_vm._v(_vm._s(row.state.name))])})],2),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.city),expression:"contact.city"}],staticClass:"form-control ",attrs:{"name":"","id":"","required":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.contact, "city", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","selected":"","hidden":""}},[_vm._v("Select City")]),_vm._l((_vm.cities),function(row,index){return _c('option',{key:index,domProps:{"value":row}},[_vm._v(_vm._s(row))])})],2),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row "},[_c('div',{staticClass:"text-center mr-auto ml-auto h5 mt-5 col-12 mb-2"},[_vm._v("Your Love Story (Not More Than 300 words)")]),_c('div',{staticClass:"form-group  col-12"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.story),expression:"contact.story"}],attrs:{"name":"","rows":"10","placeholder":"If you really want to hear about it, the first thing you'll probably want to know is were I was born, and what my idea of love was like ..."},domProps:{"value":(_vm.contact.story)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "story", $event.target.value)}}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('button',{staticClass:" btn btn-info col-4 mr-auto ml-auto",attrs:{"type":"submit"}},[_vm._v("Submit")]),_c('div',{staticClass:"text-center mr-auto ml-auto h5 mt-3 col-12 mb-2"},[_vm._v("Winners will be contacted to pick up their gift, "),_c('span',{staticStyle:{"text-decoration":"underline"}},[_vm._v(" terms and conditions apply. ")])])])])]}}])})],1)])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner home-banner"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/img/banners/MSQLoveStory.jpg"),"alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center h2 mr-auto ml-auto mt-6",staticStyle:{"width":"70%"}},[_vm._v("Share a special Love Story and "),_c('br'),_vm._v(" stand a chance to win a Gift Voucher ")])}]

export { render, staticRenderFns }