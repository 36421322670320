<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="cart page">
      <div class="">
        <div class="banner cart-banner ">
          <div class="container">
            <h3 class="title">Cart</h3>
          </div>
        </div>
        <div class="content container">
          <div class="row my-5">
            <div class="col-md-8">
            
         
              <table class="table table-responsive"  >
              <thead >
                
               
                  <tr style="background: darkblue">

                    <th scope="col" style ="color:white">Prepared Food</th>
                    <th scope="col"><i style ="color:white;margin-left:-21px; font-size:11px">(may arrive in 45 minutes)</i></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                     <th scope="col"></th>
                   
                  </tr>
                </thead>
                <thead>
                  <tr>

                    <th scope="col">Product</th>
                    <th scope="col"></th>
                    <th scope="col">Qty</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Total</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row, i) in foodcart"
                    v-bind:key="i"
                  >

                    <td class="tdb">

                      <img
                        v-if="row.product.img_url.includes('https://cdn.marketsquareng.website')"
                        :src="row.product.img_url"
                        alt=""
                        class="img"
                      >
                      <img
                        v-else
                        :src="'https://admin.sundrymarkets.com'+row.product.img_url"
                        alt=""
                        class="img"
                      >

                    </td>
                    <td class="productname tdb">{{row.product.name}}<br><small style="color:gray" v-if="row.weight_type > 0">({{row.pack}} ~{{row.weight_type * 1000 }}g)</small></td>
                    <td class="tdb">
                      <input
                        v-if="row.product.name.includes('/KG') || row.product.name.includes('/ KG')"
                        oninput="validity.valid||(value='');"
                        :id="i"
                        type="number"
                        min="0.001"
                        step="any"
                        class="number qty"
                        v-model=row.quantity
                        @keypress="restrictChars($event)"
                        @change="inputfoodChange(i, row.product.id, row.weight_type)"
                      >

                      <input
                        v-else
                        :id="i"
                        type="number"
                        min="1"
                        step="1"
                        class="number qty"
                        v-model=row.quantity
                        oninput="validity.valid||(value='');"
                        @keypress="restrictChars($event)"
                        @change="inputfoodChange(i, row.product.id, row.weight_type)"
                      />
                    </td>
                    <td class="tdb">₦{{formatPrice(row.unit_price)}} </td>
                    <td class="tdb">₦{{formatPrice(row.price)}} </td>
                    <td class="tdb"> <img
                        @click="removefoodItem(row)"
                        style="cursor:pointer;"
                        src="../assets/img/cancel.png"
                        alt=""
                        title="Remove item"
                      ></td>
                  </tr>

                </tbody>
              </table>
              





               
            </div>
              

              
            <div class="col-md-4">
              <div class="card">
                <div class="card-body">
                  <h5 class="card-title text-bold">Cart Total</h5>
                  <table class="table table-sm">

                    <tbody>
                      <tr>
                        <td class="float-left">Cart Subtotal</td>
                        <td class="float-right">₦{{formatPrice(foodcart_subtotal)}}</td>
                      </tr>

                    </tbody>
                  </table>
                  <div class="mt-4 total d-flex  justify-content-between">
                    <p>Total</p>
                    <h5>₦{{formatPrice( foodcart_total)}}</h5>
                  </div>
                  <button
                    id="continue"
                    @click="$router.push('home')"
                  >Continue Shopping</button>
                  <button
                    v-bind:disabled="foodcart_total < 1000"
                    v-bind:class="foodcart_total < 1000 ? 'disabled': ''"
                    @click="handleFoodCheckout"
                  >Proceed to checkout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import TopNav from '@/components/TopNav.vue'
import Footer from '@/components/Footer.vue'
import Tops from '@/components/Tops.vue'
export default {
  name: 'AllCart',
  components: {
    TopNav, Footer,Tops
  },
  data () {
    return {
      cart: [],
      foodcart: [],
      cart_total: 0,
      foodcart_total: 0,
    }
  },
  beforeMount () {
    this.$store.dispatch('ToggleShowSearch', true)
  },
  mounted () {
    // this.cart = this.$store.getters.cart;

    // this.cart.forEach(i => {
    //   this.cart_subtotal += Number(i.price);
    // })
    // this.cart_total = this.cart_subtotal
    this.getProductInCart();
  },
  computed: {
    cart_subtotal () {
      this.cart = this.$store.getters.cart;
      let total = 0;
      this.cart.forEach(i => {
        total += Number(i.price);
      })
      this.cart_total = total;
      return total;
    },
    foodcart_subtotal () {
      this.foodcart = this.$store.getters.foodcart;
      let total = 0;
      this.foodcart.forEach(i => {
        total += Number(i.price);
      })
      this.foodcart_total = total;
      return total;
    }
  },
  
  watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Your Cart';
        }
    },
  },

  methods: {
     getProductInCart(){

      let cart = this.$store.getters.cart;

      let productsInCart = cart.reduce((acc,prev) => { acc.push(prev.product.id); return acc }, [])

      let req = {
        what: "getProductInCart",
        showLoader: false,
        params : {
          'cart' : JSON.stringify(productsInCart)
        }
      };

      this.$request.makeGetRequest(req)
        .then(res => {

          // if (res.type == 'getproduct') {
            let pro = res.data.data;
            if (pro.length > 0){
              cart = cart.map((item) => {
                item.product.price = pro.find((product) => item.product.id == product.id).sellingprice
                item.unit_price = item.product.price
                item.price = item.quantity * item.unit_price
                return item
              })
            }
            this.$store.dispatch('addToCart', cart)
            this.loader.hide()
          // }

        })
        .catch(error => {
          
        });
    },

    removeItem (row) {
      let index;
      let cart = this.$store.getters.cart;
      cart.forEach((i, ind) => {
        if (i.product.id == row.product.id) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addToCart', cart)
    },

    removefoodItem (row) {
      let index;
      let cart = this.$store.getters.foodcart;
      cart.forEach((i, ind) => {
        if (i.product.id == row.product.id && i.weight_type == row.weight_type) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addTofoodCart', cart)
    },
    
    inputChange (id, product_id) {

      var value = document.getElementById(id).value;
      if (value == '') {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updateCartQuantity(value, product_id, "input");
    },
    
    inputfoodChange (id, product_id, kg) {

      var value = document.getElementById(id).value;
      if (value == '') {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updatefoodCartQuantity(value, product_id, "input", kg);
    },

    updateCartQuantity (value, product_id, action) {
      let cart_array = this.$store.getters.cart;
      cart_array.forEach(i => {
        if (product_id == i.product.id) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;

          }
        }
      })
      this.$store.dispatch('addToCart', cart_array)
    },

    updatefoodCartQuantity (value, product_id, action, kg) {
      let cart_array = this.$store.getters.foodcart;
      cart_array.forEach(i => {
        if (product_id == i.product.id && kg == i.weight_type) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;

          }
        }
      })
      this.$store.dispatch('addTofoodCart', cart_array)
    },

    handleFoodCheckout () {
      if (this.$store.getters.isLoggedIn) {
        let cart = this.$store.getters.cart;
        let cartitems = [];
        cart.forEach(i => {
          let x = {
            product_id: i.product.id,
            quantity: i.quantity,
            unit_price: i.unit_price,
            price: i.price
          }
          cartitems.push(x)
        })

        let data = {
          user_id: this.$store.getters.user.id,
          totalquantity: cart.length,
          total_price: this.cart_total,
          checkout: "0",
          storeid: this.$store.getters.store.id,
          checkoutitems: cartitems
        }
        console.log(data);
        var req = {
          what: "createcart",
          data: data,
          showLoader: true
        };
        this.$request
          .makePostRequest(req)
          .then(response => {
            this.$router.push({ name: 'FoodCheckout' })

          })
          .catch(error => {
            console.log(error)
            this.$swal.fire("Error", error.message, "error");
          });
      }
      else {
        this.$router.push({ name: 'FoodCheckout' })
      }
    },
    restrictChars: function ($event) {
      if ($event.key !== '-') {
        return true
      }
      else {
        $event.preventDefault();
      }
    },
    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
  }
}

</script>

<style scoped>
.row{
  margin-left:0px! important;

}
#continue {
  background: #e8e8e8;
  color: #000066;
}
.cart .card {
  position: sticky;
  top: 25%;
}
tr {
  background: white;
}
@media screen and (max-width:500px) {
  #continue {
      top: 37% !important;
  }
  .cart .card button {
    margin-top: 50px !important;
  }
}
.tdb{
border:0px !important;
}
</style>