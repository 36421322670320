<template>
    <div ref='pro' v-if='viewproduct' class="modal fade" id="foodproduct" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static"  aria-labelledby="exampleModalLabel" aria-hidden="true">
       
        <div class="content-desktop modal-dialog modal-lg">
          <div class=" modal-content">

            <!-- modal header -->
            <div class="modal-header">              
              <button @click.prevent='doSomethingOnHidden($event)' type="button" class="close" data-dismiss="modal"  aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <!-- Modal Body -->
            <div class="modal-body pt-0">
              <div class="row">
                
                <div class="col-6" v-if="pro.variation_weight1> 0">
                  <p v-if="pro.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                      <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
                  </p>

                  <p class="mb-1" style="font-weight: bold;font-size: 20px;">Please select a variation</p>
                 
                    <p v-if ="pro.discount > 0" style="font-size: 12px;color:grey">Final cost by weight   <small style="color:grey; font-size:10px;">(₦ {{ (discountPriceThree())}}/Kg)</small> &nbsp;
                       <s style="color:grey; font-size:9px;">(₦ {{(pro.sellingprice * pro.variation_weight3)}}/Kg)</s>
                        <span style="background-color: #ffcccb; font-size:12px; color: #000066;" class="float-right pr-1 pl-1"> <b> {{-pro.discount}}%</b></span>
                    </p>
                      
                     <p v-else style="font-size: 12px;color:grey">Final cost by weight  <small style="color:grey; font-size:10px;">(₦ {{(pro.sellingprice * pro.variation_weight3)}}/Kg)</small></p>  
                   
                   <!-- if big pack -->
                  <div class="d-flex  justify-content-between">
                    <div class="var">
                      <span>{{pro.variation_name1 }} (~{{pro.variation_weight1*1000 }}grams)</span> <br>
                      
                      <span class="price">
                        
                        <span v-if ="pro.discount > 0">
                           <span >
                            ₦{{discountPriceOne()}}
                           </span> <br>
                           <span>
                           <s style="color:#808080;font-size:10px;">  ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight1) ).toFixed(2)) }}</s>
                           </span>
                        </span> 

                       <span v-else>
                       
                        <span v-if="pro.promo">
                         <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}
                          {{pro.promo.value_percent}}
                          </span> <br>
                          
                           <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span>
                        </span>
                        <span v-else-if="pro.old_price">
                         <span> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight1) ).toFixed(2)) }}</span>
                          
                          <br>

                            <span style="color:#808080;font-size:12px;">
                            <s>₦{{ formatPrice(pro.old_price) }}</s>
                          </span> 
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight1) ).toFixed(2)) }}</span>
                      </span>
                    </span>
                    </div>                 

                    <div  class="mt-2">
                      <button @click="decreaseQuantity('tp_modal', pro.id, pro.variation_weight1)" class="decrease mr-2 ml-1" 
                             style="color: white;background-color: navy;border: none;border-radius: 5px;">  - 
                       </button>
                       <input style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          :id="'tp_modal'"
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, pro.variation_weight1)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, pro.variation_weight1)">

                        <input style="width:20px; border:none"
                          v-else
                          :id="'tp_modal'"
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, pro.variation_weight1)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, pro.variation_weight1)"
                        />

                         <button  v-if="isinCart(pro.id, pro.variation_weight1)"  @click="increaseQuantity('mtp_modal', pro.id, pro.variation_weight1)" class="increase ml-2" 
                                 style="color: white;background-color: navy;border: none;border-radius: 5px;"> +
                          </button>

                           <button v-else @click="addTofoodCart(pro, pro.variation_weight1, pro.variation_name1)" class="increase ml-2" 
                                 style="color: white;background-color: navy;border: none;border-radius: 5px;"> +
                          </button>
                   </div>
                  </div>
                  
                  <hr>
                     
                  <!-- if medium pack -->
                  <div class="d-flex  justify-content-between ">
                    <div class="var">
                      {{pro.variation_name2 }} (~{{pro.variation_weight2*1000 }}grams) <br>
                      
                      <p class="price">
                        <span v-if ="pro.discount > 0">
                          <span>₦{{discountPriceTwo()}}</span> <br>
                           
                            <span style="color:#808080;font-size:10px;"><s>  ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight2) ).toFixed(2)) }}</s>
                           </span>
                        </span> 
                        <span v-else> 
                        <span v-if="pro.promo">
                        <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span> <br>
                          
                           <span style="color:#808080;font-size:10px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> 
                        </span>
                        <span v-else-if="pro.old_price">
                          <span> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight2)).toFixed(2)) }}</span><br>
                          
                           <span style="color:#808080;font-size:10px;"><s>₦{{ formatPrice(pro.old_price) }}</s></span>
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight2) ).toFixed(2)) }}</span>
                        
                        </span> 
                         
                      </p>
                    </div>
                 
                     <div class="mt-2">
                    
                      <button  :id="'addtp2_modal'" @click="decreaseQuantity('mtp_modal', pro.id, pro.variation_weight2)"
                               class="decrease mr-2 ml-1" style="color: white;background-color: navy;border: none;border-radius: 5px;">  - </button>
                       <input style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          id='mtp_modal'
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, pro.variation_weight2)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, pro.variation_weight2)"
                        >
                      <input  style="width:20px; border:none" v-else
                          id='mtp_modal'
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, pro.variation_weight2)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, pro.variation_weight2)" />

                      <button :id="'addtp2_modal'"  v-if="isinCart(pro.id, pro.variation_weight2)" 
                      @click="increaseQuantity('mtp_modal', pro.id, pro.variation_weight2)" class=" increase ml-2" style=" color: white;background-color: navy;border: none; border-radius: 5px;"  >+</button>
                      
                      <button :id="'addtp2_modal'"v-else @click="addTofoodCart(pro, pro.variation_weight2, pro.variation_name2)" class=" increase ml-2" style=" color: white;background-color: navy;border: none; border-radius: 5px;"  >+</button>
                    </div>
                    
                  </div>

                  <hr>
                  
                  <!-- if small pack -->
                  <div class="d-flex  justify-content-between">
                    <div class="var">
                      {{pro.variation_name3 }} (~{{pro.variation_weight3*1000 }}grams) <br>

                      <p class="price">
                      <span v-if ="pro.discount > 0">
                           <span>₦{{discountPriceThree()}}</span> <br>
                           
                           <span style="color:#808080;font-size:10px;"><s>  ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight3) ).toFixed(2)) }}</s>
                           </span>
                        </span> 
                        <span v-else>
                        <span v-if="pro.promo">
                        
                          <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span> <br>
                           <span style="color:#808080;font-size:10px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> 
                        </span>
                        <span v-else-if="pro.old_price">
                         <span> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight3)).toFixed(2)) }}</span> <br>
                          
                          <span style="color:#808080;font-size:10px;"><s>₦{{ formatPrice(pro.old_price) }}</s></span> 
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * pro.variation_weight3)).toFixed(2))}}</span>
                        </span>
                      </p>
                    </div>                    
                    <div class="mt-2 d-non">
                    <button @click="decreaseQuantity('stp_modal', pro.id,pro.variation_weight3)" class="mr-2 ml-1 decrease"
                            style=" color: white;background-color: navy;border: none; border-radius: 5px;" >-</button>

                     <input  style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          id='mtp_modal'
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id,pro.variation_weight3)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('stp_modal', pro.id,pro.variation_weight3)"
                        >

                        <input  style="width:20px; border:none"
                          v-else
                          id='stp_modal'
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, pro.variation_weight3)"
                          @keypress="restrictChars($event)"
                          @change="inputChange('stp_modal', pro.id,pro.variation_weight3)"
                        />
                    <button v-if="isinCart(pro.id, pro.variation_weight3)"
                        @click="increaseQuantity('stp_modal', pro.id, pro.variation_weight3)" class=" increase ml-2"
                         style=" color: white;background-color: navy;border: none; border-radius: 5px;" >+</button>

                    <button v-else @click="addTofoodCart(pro, pro.variation_weight3, pro.variation_name3)"
                         class=" increase ml-2"
                         style=" color: white;background-color: navy;border: none; border-radius: 5px;" >+</button>
                    </div>
                  </div>                  
                </div>
                
                <div class="col-6" v-else>
                   <p v-if="pro.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                      <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
                  </p>

                  <p class="mb-1" style="font-weight: bold;font-size: 20px;">Please add item</p>

                  <div class="justify-content-between">
                    <div class="var">
                      <span>{{pro.description}}</span> <br>
                      
                      <span class="price">
                      <span v-if="pro.discount">
                       <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                          <span>₦{{ discountedPrice() }}</span>
                      </span>
                      <span v-else>
                        <span v-if="pro.promo">
                          <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                          <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span>
                        </span>
                        <span v-else-if="pro.old_price">
                          <span style="color:#808080;font-size:12px;">
                            <s>₦{{ formatPrice(pro.old_price) }}</s>
                          </span> 
                          
                          <br>

                          <span>₦{{ formatPrice(pro.sellingprice) }}</span>
                        </span>

                        <!-- <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * variation[0]['kg']) / pro.avg_weight).toFixed(2)) }}</span> -->
                        <span v-else class="price"> ₦{{ formatPrice(pro.sellingprice) }}</span>
                      </span>
                      </span>
                    </div>                 

                    <div class="mt-5">
                      <button class="addquantity selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" v-if="isinCart(pro.id, 'noKg')">

                        <div @click="decreaseQuantity('tp_modal', pro.id, 'noKg')" class="decrease mr-2 ml-1"> - </div>

                        <input
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          :id="'tp_modal'"
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, 'noKg')"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, 'noKg')"
                        >

                        <input
                          v-else
                          :id="'tp_modal'"
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, 'noKg')"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, 'noKg')"
                        />

                        <div @click="increaseQuantity('tp_modal', pro.id, 'noKg')" class="increase ml-2"> + </div>
                      </button> 

                      <button class="addtocart selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(pro, 'noKg', 'noKg')" v-else>
                        <span>Add</span>
                      </button>
                    </div>   
                  </div>
                </div>

                <div class="mb-4" :class="isProductPromotion() ? 'col-2' : 'col-6'">
                  <div class="product-text" >
                   
                      <img
                    v-if="pro.img_url.includes('https://cdn.marketsquareng.website')"
                    :src="pro.img_url"
                    alt=""
                    class="img-fluid"
                  >
                  <img
                    v-else
                    :src="image_url+pro.img_url"
                    alt=""
                    class="img-fluid"
                  >
                   <p class="name mb-2" style="height: auto !important">{{pro.name}}</p>
                    
                    <p v-if="pro.isBlackfriday == 1"> <span style="background:#000; color:#fff; font-size:12px; border-radius:3px; padding:3px 9px">Black Friday Deal</span> </p>

                    <p
                      v-if="pro.description && (pro.description.includes('/KG') || pro.description.includes('/ KG'))"
                      class="weight"
                    >(Per Kg)</p>
                  </div>
                  <div class="product-cat d-flex">
                    <span class="badge text-lowercase">{{pro.promotion != null ? pro.promotion : pro.category}}</span>
                  </div>
                  <div class="product-footer">
                   <div class="col-md-6 col-sm-12" v-if="isProductPromotion()">
                  <div class="description p-0 m-0">
                    {{pro.description}}
                  </div>
                </div>




                      <div class="row">
                    <button class="btn"   @click.prevent='doSomethingOnHidden($event)'
                data-dismiss="modal">
                    CONTINUE SHOPPING
                    </button>

                      <button class="btn"  @click='viewCart()'>
                      VIEW CART
                    </button>
                    </div>

                      
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
        
        <div id="content-mobile">
             <div >
          <div class="modal-dialog modal-sm">
            <div class="modal-content">
              <div class="modal-header">

                <button
                  @click.prevent='doSomethingOnHidden($event)'
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
                <!--modalbody-->
              <div class="modal-body pt-0">
              <div class="">
                
                <div class="mb-4" :class="isProductPromotion() ">
                  <div class="product-text" >
                   
                      <img v-if="pro.img_url.includes('https://cdn.marketsquareng.website')"
                           :src="pro.img_url"
                            alt=""
                            class="img-fluid"
                       >
                      <img v-else :src="image_url+pro.img_url"
                          alt=""  class="img-fluid" >
                      <p class="name mb-2" style="height: auto !important">{{pro.name}}</p>
                    
                       <p v-if="pro.isBlackfriday == 1"> <span style="background:#000; color:#fff; font-size:12px; border-radius:3px; padding:3px 9px">Black Friday Deal</span> </p>

                      <p
                      v-if="pro.description && (pro.description.includes('/KG') || pro.description.includes('/ KG'))"
                      class="weight" >(Per Kg)</p>
                    </div>
                  <div class="product-cat d-flex">
                    <span class="badge text-lowercase">{{pro.promotion != null ? pro.promotion : pro.category}}</span>
                  </div>
                  <div class="product-footer">
                   <div class="col-md-6 col-sm-12" v-if="isProductPromotion()">
                  <div class="description p-0 m-0">
                    {{pro.description}}
                  </div>
                </div>




                     

                      
                  </div>
                <div class="" v-if="pro.avg_weight > 0">
                  <p v-if="pro.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                      <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
                  </p>

                  <p class="mb-1" style="font-weight: bold;font-size: 20px;">Please select a variation</p>
                  <p style="font-size: 12px;color:grey">Final cost by weight</p>

                  <!-- if big pack -->
                  <div class="d-flex  justify-content-between">
                    <div class="var">
                      <span>{{variation[0]['title'] }} (~{{variation[0]['kg']*1000 }}g)</span> <br>
                      
                      <span class="price">
                        <span v-if="pro.promo">
                          <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                          <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span>
                        </span>
                        <span v-else-if="pro.old_price">
                          <span style="color:#808080;font-size:12px;">
                            <s>₦{{ formatPrice(pro.old_price) }}</s>
                          </span> 
                          
                          <br>

                          <span>₦{{ formatPrice(pro.sellingprice/variation[0]['kg']) }}</span>
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * variation[0]['kg']) / pro.avg_weight).toFixed(2)) }}</span>
                      </span>
                    </div>                 

                    <!-- <div class="mt-2">
                      <button class="addquantity selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" v-if="isinCart(pro.id, variation[0]['kg'])">

                        <div @click="decreaseQuantity('tp_modal', pro.id, variation[0]['kg'])" class="decrease mr-2 ml-1"> - </div>

                        <input
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          :id="'tp_modal'"
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, variation[0]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, variation[0]['kg'])"
                        >

                        <input
                          v-else
                          :id="'tp_modal'"
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, variation[0]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, variation[0]['kg'])"
                        />

                        <div @click="increaseQuantity('tp_modal', pro.id, variation[0]['kg'])" class="increase ml-2"> + </div>
                      </button> 

                      <button class="addtocart selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(pro, variation[0]['kg'], variation[0]['title'])" v-else>
                        <span>Add</span>
                      </button>
                    </div> --> 
                    
                    <div  class="mt-2">
                      <button @click="decreaseQuantity('tp_modal', pro.id, variation[0]['kg'])" class="decrease mr-2 ml-1" 
                             style="color: white;background-color: navy;border: none;border-radius: 5px;">  - 
                       </button>
                       <input style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          :id="'tp_modal'"
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, variation[0]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, variation[0]['kg'])">

                        <input style="width:20px; border:none"
                          v-else
                          :id="'tp_modal'"
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, variation[0]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, variation[0]['kg'])"
                        />

                         <button  v-if="isinCart(pro.id, variation[0]['kg'])"  @click="increaseQuantity('mtp_modal', pro.id, variation[0]['kg'])" class="increase ml-2" 
                                 style="color: white;background-color: navy;border: none;border-radius: 5px;"> +
                          </button>

                           <button v-else @click="addTofoodCart(pro, variation[0]['kg'], variation[0]['title'])" class="increase ml-2" 
                                 style="color: white;background-color: navy;border: none;border-radius: 5px;"> +
                          </button>
                   </div>
                  </div>
                  
                  <hr>
                     
                  <!-- if medium pack -->
                  <div class="d-flex  justify-content-between ">
                    <div class="var">
                      {{variation[1]['title'] }} (~{{variation[1]['kg']*1000 }}grams) <br>
                      
                      <p class="price">
                        <span v-if="pro.promo">
                          <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                          <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span>
                        </span>
                        <span v-else-if="pro.old_price">
                          <span style="color:#808080;font-size:12px;"><s>₦{{ formatPrice(pro.old_price) }}</s></span> <br>
                          <span>₦{{ formatPrice(pro.sellingprice)}}</span>
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * variation[1]['kg']) / pro.avg_weight).toFixed(2)) }}</span>
                      </p>
                    </div>
                    
                    <!--<div class="mt-2">
                      <button :id="'addtp2_modal'" class="addquantity selectbtnm m-0" :style="{'text-align: left !important' : isProductPromotion()}" v-if="isinCart(pro.id, variation[1]['kg'])">
                        <div @click="decreaseQuantity('mtp_modal', pro.id, variation[1]['kg'])" class="decrease mr-2 ml-1">-</div>
                        <input
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          id='mtp_modal'
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, variation[1]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, variation[1]['kg'])"
                        >

                        <input
                          v-else
                          id='mtp_modal'
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, variation[1]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, variation[1]['kg'])"
                        />

                        <div @click="increaseQuantity('mtp_modal', pro.id, variation[1]['kg'])" class=" increase ml-2">+</div>
                      </button>

                      <button :id="'btntp2_modal'" class="addtocart selectbtnm m-0" :style=" {'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(pro, variation[1]['kg'], variation[1]['title'])" v-else>
                        <span>Add</span>
                      </button>
                    </div>-->

                     <div class="mt-2">
                    
                      <button  :id="'addtp2_modal'" @click="decreaseQuantity('mtp_modal', pro.id, variation[1]['kg'])" class="decrease mr-2 ml-1" style="color: white;background-color: navy;border: none;border-radius: 5px;">  - </button>
                       <input style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          id='mtp_modal'
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, variation[1]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, variation[1]['kg'])"
                        >
                      <input  style="width:20px; border:none" v-else
                          id='mtp_modal'
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, variation[1]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('mtp_modal', pro.id, variation[1]['kg'])" />

                      <button :id="'addtp2_modal'"  v-if="isinCart(pro.id, variation[1]['kg'])" 
                      @click="increaseQuantity('mtp_modal', pro.id, variation[1]['kg'])" class=" increase ml-2" style=" color: white;background-color: navy;border: none; border-radius: 5px;"  >+</button>
                      
                      <button :id="'addtp2_modal'"v-else @click="addTofoodCart(pro, variation[1]['kg'], variation[1]['title'])" class=" increase ml-2" style=" color: white;background-color: navy;border: none; border-radius: 5px;"  >+</button>
                    </div>
                    
                  </div>

                  <hr>
                  
                  <!-- if small pack -->
                  <div class="d-flex  justify-content-between">
                    <div class="var">
                      {{variation[2]['title'] }} (~{{variation[2]['kg']*1000 }}grams) <br>

                      <p class="price">
                        <span v-if="pro.promo">
                          <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                          <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span>
                        </span>
                        <span v-else-if="pro.old_price">
                          <span style="color:#808080;font-size:12px;"><s>₦{{ formatPrice(pro.old_price) }}</s></span> <br>
                          <span>₦{{ formatPrice(pro.sellingprice)}}</span>
                        </span>

                        <span v-else class="price"> ₦{{ formatPrice(((pro.sellingprice * variation[2]['kg']) / pro.avg_weight).toFixed(2))}}</span>
                      </p>
                    </div>
  
                    <div class="mt-2 d-non">
                      <!--  <button :id="'addtp3_modal'" class="addquantity selectbtns m-0" :style="{'text-align: left !important' : isProductPromotion()}"
                      // //  v-if="isinCart(pro.id, variation[2]['kg'])">
                      // //   <div @click="decreaseQuantity('stp_modal', pro.id, variation[2]['kg'])" class="mr-2 ml-1 decrease">-</div>

                      // //   <input
                      // //     v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                      // //     oninput="validity.valid||(value='');"
                      // //     id='mtp_modal'
                      // //     type="number"
                      // //     min="0.001"
                      // //     step="any"
                      // //     class="number"
                      // //     :value="iscartQTY(pro.id, variation[2]['kg'])"
                      // //     @keypress="restrictChars($event)"
                      // //     @change="inputChange('stp_modal', pro.id, variation[2]['kg'])"
                      // //   >

                      // //   <input
                      // //     v-else
                      // //     id='stp_modal'
                      // //     type="number"
                      // //     min="0"
                      // //     step="1"
                      // //     class="number"
                      // //     :value="iscartQTY(pro.id, variation[2]['kg'])"
                      // //     @keypress="restrictChars($event)"
                      // //     @change="inputChange('stp_modal', pro.id, variation[2]['kg'])"
                      // //   />

                      // //   <div @click="increaseQuantity('stp_modal', pro.id, variation[2]['kg'])" class=" increase ml-2">+</div>
                       </button>-->

                     <!-- <button :id="'btntp3_modal'" class="addtocart selectbtns m-0" :style=" {'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(pro, variation[2]['kg'], variation[2]['title'])" v-else>
                          <span>Add</span>
                      </button>-->
                    </div>
                    
                    <div class="mt-2 d-non">
                    <button @click="decreaseQuantity('stp_modal', pro.id, variation[2]['kg'])" class="mr-2 ml-1 decrease"
                            style=" color: white;background-color: navy;border: none; border-radius: 5px;" >-</button>

                     <input  style="width:20px; border:none"
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          id='mtp_modal'
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, variation[2]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('stp_modal', pro.id, variation[2]['kg'])"
                        >

                        <input  style="width:20px; border:none"
                          v-else
                          id='stp_modal'
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, variation[2]['kg'])"
                          @keypress="restrictChars($event)"
                          @change="inputChange('stp_modal', pro.id, variation[2]['kg'])"
                        />
                    <button v-if="isinCart(pro.id, variation[2]['kg'])"
                        @click="increaseQuantity('stp_modal', pro.id, variation[2]['kg'])" class=" increase ml-2"
                         style=" color: white;background-color: navy;border: none; border-radius: 5px;" >+</button>

                    <button v-else @click="addTofoodCart(pro, variation[2]['kg'], variation[2]['title'])"
                         class=" increase ml-2"
                         style=" color: white;background-color: navy;border: none; border-radius: 5px;" >+</button>
                    </div>
                  </div>                  
                </div>
                
                <div class="col-6" v-else>
                   <p v-if="pro.isOnlineonly == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
                      <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
                  </p>

                  <p class="mb-1" style="font-weight: bold;font-size: 20px;">Please add item</p>

                  <div class="justify-content-between">
                    <div class="var">
                      <span>{{pro.description}}</span> <br>
                      
                      <span class="price">
                          <span v-if = "pro.discount ">
                            <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                            <span>₦{{ discountedPrice() }}</span>
                          </span>
                          <span v-else>
                            <span v-if="pro.promo">
                              <span style="color:#808080;font-size:13px;"><s>₦{{ formatPrice(pro.sellingprice) }}</s></span> <br>
                              <span>₦{{ formatPrice(Math.round((pro.promo.value_percent/100)*pro.sellingprice)) }}</span>
                            </span>
                              <span v-else-if="pro.old_price">
                                <span style="color:#808080;font-size:12px;">
                                  <s>₦{{ formatPrice(pro.old_price) }}</s>
                                </span> 
                                
                                <br>

                                <span>₦{{ formatPrice(pro.sellingprice) }}</span>
                              </span>
                              <span v-else class="price"> ₦{{ formatPrice(pro.sellingprice) }}</span>
                          </span>
                      </span>
                    </div>                 

                    <div class="mt-5">
                      <button class="addquantity selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" v-if="isinCart(pro.id, 'noKg')">

                        <div @click="decreaseQuantity('tp_modal', pro.id, 'noKg')" class="decrease mr-2 ml-1"> - </div>

                        <input
                          v-if="pro.description.includes('/KG') || pro.description.includes('/ KG')"
                          oninput="validity.valid||(value='');"
                          :id="'tp_modal'"
                          type="number"
                          min="0.001"
                          step="any"
                          class="number"
                          :value="iscartQTY(pro.id, 'noKg')"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, 'noKg')"
                        >

                        <input
                          v-else
                          :id="'tp_modal'"
                          type="number"
                          min="0"
                          step="1"
                          class="number"
                          :value="iscartQTY(pro.id, 'noKg')"
                          @keypress="restrictChars($event)"
                          @change="inputChange('tp_modal', pro.id, 'noKg')"
                        />

                        <div @click="increaseQuantity('tp_modal', pro.id, 'noKg')" class="increase ml-2"> + </div>
                      </button> 

                      <button class="addtocart selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(pro, 'noKg', 'noKg')" v-else>
                        <span>Add</span>
                      </button>
                    </div>   
                  </div>
                </div>
                       <div class="">
                    <button class="btn"   @click.prevent='doSomethingOnHidden($event)'
                data-dismiss="modal">
                    CONTINUE SHOPPING
                    </button>

                      <button class="btn"  @click='viewCart()'>
                      VIEW CART
                    </button>
                    </div>
                </div>                
              </div>
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>

      
</template>

<script>
import * as $ from "jquery";
export default {
  name: 'PreparedFoodModal',
  props: ['viewproduct', 'pro', 'products'],

  data () {
    return {
      page_namer : decodeURIComponent(/[^/]*$/.exec(window.location.href)[0]),
      category: '',
      image_url: this.$request.url,

      variation:[
        {'title':'Big pack', 'kg':'1.000'},
        {'title':'Medium pack', 'kg':'0.600'},
        {'title':'Small pack', 'kg':'0.400'}
      ],
      
      foodcart: {
        quantity: "",
        unit_price: "",
        price: "",
        weight_type:"",
        product: {
          id: "",
          name: "",
          price: "",
          img_url: ""
        }
      },
    }
  },

  mounted(){},

  
  computed: {
    
  },

  methods: {   

    //((pro.sellingprice * pro.variation_weight1) / pro.avg_weight).toFixed(2)
    discountPriceOne(pro){ 
           let original_price = Number( ((this.pro.sellingprice * this.pro.variation_weight1)).toFixed(2));
           let discount_price = Number(this.pro.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price))

           return final_discount;
        },
        discountPriceTwo(pro){ 
           let original_price = Number( ((this.pro.sellingprice * this.pro.variation_weight2)).toFixed(2));
           let discount_price = Number(this.pro.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price))

           return final_discount;
        },
        discountPriceThree(pro){ 
           let original_price = Number( ((this.pro.sellingprice * this.pro.variation_weight3)).toFixed(2));
           let discount_price = Number(this.pro.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price))

           return final_discount;
        },

        discountedPrice (pro){ 

           let original_price = Number( this.pro.sellingprice );
          
           let discount_price = Number(this.pro.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price).toFixed(2))

           return Number(final_discount .toFixed(2));
        },
     avgPrice (a,b){ 
            return Number((a/b).toFixed(2)).toLocaleString(); 
        },

     viewCart () {
      
        $("#foodproduct").modal("hide");
         $("#sidecart").modal("show");
       // this.$router.push({ name: 'Cart' })
      
      
    },
    // # input validation
    restrictChars: function ($event) {
        if ($event.key !== '-' || $event.code !== 'Minus') {
            return true
        } else {
            $event.preventDefault();
        }
    },

    // thousand formatting
    formatPrice (price) {
        var str = price.toString().split(".");
        if (str[0].length >= 3) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
        }
        if (!str[1]) {
            str[1] = "00";
        }
        return str.join(".");
    },
    
    // # check variant;
    isinCart(id,kg){
      let cart = this.$store.getters.foodcart;
      let isAdded = false;
      cart.forEach((i) => {
        if (i.product.id == id && i.weight_type == kg) {
          isAdded = true;
        }
      })
      return isAdded;
    },
    
    // # check qty;
    iscartQTY(id,kg){
      let cart = this.$store.getters.foodcart;
      let qty = 0;
      cart.forEach((i) => {
        if (i.product.id == id && i.weight_type == kg) {
          qty = i.quantity;
        }
      })
      return qty;
    },
 
    // # fn() to add food variant to cart;
    addTofoodCart (product, kg, pack) {
       let original_price = Number( ((product.sellingprice * kg)).toFixed(2));
           let discount_price = Number(product.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price))

        
        if (product.promo) {
            product["newprice"] = Math.round( (product.promo.value_percent / 100) * product.sellingprice );
        } 
        else {
            if(kg == 'noKg'){
              product["newprice"] = product.sellingprice; //product.sellingprice;
            }
            else if (product.discount){
             product["newprice"] = final_discount;
            }
            else{
              product["newprice"] = (product.sellingprice * kg); //product.sellingprice;
            }
        }
         

        let foodcart = {
            product: {}
        }

        foodcart.quantity = 1;

        foodcart.unit_price = product.newprice;
        foodcart.price = product.newprice * foodcart.quantity;
        foodcart.weight_type = kg;
        foodcart.pack = pack;
        
        foodcart.product.id = product.id;
        foodcart.product.name = product.name;
        foodcart.product.price = product.newprice;
        foodcart.product.img_url = product.img_url;


        let food_cart_array = this.$store.getters.foodcart;
        let check = [];
        
        if (food_cart_array.length > 0) {
            food_cart_array.forEach(i => {
              if (i.product.id == foodcart.product.id && i.weight_type == kg) {
                  check.push(1);
                  i.quantity = parseInt(i.quantity) + 1;
                  i.price += Number(i.unit_price);
              }
              else {
                  check.push(0)
              }
            })
            
            if (!check.includes(1)) {
              food_cart_array.push(foodcart)
            }
        }
        else {
            food_cart_array.push(foodcart)
        }
        this.$store.dispatch('addTofoodCart', food_cart_array);
    },

    // # fn() to add qty to order;
    increaseQuantity (id, product_id, kg) {
      var el   = document.getElementById(id);
      var v    = Number(el.value) + 1;
      el.value = v;
      this.updateCartQuantity(v, product_id, "+", kg);
      
    },

    // # fn() to remove qty from order;
    decreaseQuantity (id, product_id, kg) {
        var el    = document.getElementById(id);
        let value = el.value;

        if (value != 0) {
          value = Number(value) - 1;
          if (value == 0) {
            this.removeFromCart(product_id,kg)
          }
          else {
            el.value = value;
          }
        }
        else {
            value = 1;
            el.value = value;
        }

        this.updateCartQuantity(value, product_id, "-", kg);
    },

    // # fn() to query lDB;
    updateCartQuantity (value, product_id, action, kg) {
        let cart_array = this.$store.getters.foodcart;

        cart_array.forEach(i => {
            if (product_id == i.product.id && i.weight_type == kg) {
              if (action == '+') {
                  i.quantity = parseInt(i.quantity) + 1;
                  i.price += parseInt(i.unit_price);
              }
              else if (action == '-') {
                  i.quantity = parseInt(i.quantity) - 1;
                  i.price -= parseInt(i.unit_price);
              }
              else {
                  i.quantity = value;
                  i.price = parseInt(i.unit_price) * value;
              }
            }
        })
        this.$store.dispatch('addTofoodCart', cart_array)
    },

    // # fn() to delete cart item;
    removeFromCart (id,kg) {
        let index;
        let cart = this.$store.getters.foodcart;
        cart.forEach((i, ind) => {
            if (i.product.id == id && i.weight_type == kg) {
              index = ind
            }
        })
        cart.splice(index, 1);
        this.$store.dispatch('addTofoodCart', cart)
    },

    inputChange (id, product_id, kg) {
      var el = document.getElementById(id);
      let value = el.value;

      if (value == 0) {
          this.removeFromCart(product_id, kg);
      }
      else if (Math.sign(value) == '-1') {
          value = 1;
          el.value = 1;
      }
      else {

      }
      this.updateCartQuantity(value, product_id, "input", kg);
    },


    // ### Extras fn()s ->>
    doSomethingOnHidden ($event) {
        // history.back()
        let cart = this.$store.getters.foodcart;
        if(Array.isArray(this.products)) {
          this.products.forEach(i => {
              i.hidebtn = false;
              i.hideqty = true;
              i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
              cart.forEach(j => {
                  if (i.id == j.product.id) {
                      i.hidebtn = true;
                      i.hideqty = false;
                      i.cart_qty = j.quantity;
                  }
              })
          })
        } 
        else {
          this.products.food.forEach(i => {
            i.hidebtn = false;
            i.hideqty = true;
            i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
            cart.forEach(j => {
              if (i.id == j.product.id) {
                i.hidebtn = true;
                i.hideqty = false;
                i.cart_qty = j.quantity;
              }

            })
          })
          
          // document.querySelector(".addtocart").classList.remove('hideqty');
          // document.querySelector(".addquantity").classList.add('hideqty')

          // console.log('i see')

          // this.products.beverages.forEach(i => {
          //   i.hidebtn = false;
          //   i.hideqty = true;
          //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          //   cart.forEach(j => {
          //     if (i.id == j.product.id) {
          //       i.hidebtn = true;
          //       i.hideqty = false;
          //       i.cart_qty = j.quantity;
          //     }

          //   })
          // })
          // this.products.water.forEach(i => {
          //   i.hidebtn = false;
          //   i.hideqty = true;
          //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          //   cart.forEach(j => {
          //     if (i.id == j.product.id) {
          //       i.hidebtn = true;
          //       i.hideqty = false;
          //       i.cart_qty = j.quantity;
          //     }

          //   })
          // })
          // this.products.commodities.forEach(i => {
          //   i.hidebtn = false;
          //   i.hideqty = true;
          //   i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          //   cart.forEach(j => {
          //     if (i.id == j.product.id) {
          //       i.hidebtn = true;
          //       i.hideqty = false;
          //       i.cart_qty = j.quantity;
          //     }

          //   })
          // })
        }
    },

    isProductPromotion (){
        if(this.pro.promotion){
            if(this.pro.promotion.toLowerCase().includes('combos'))
                return true;
        }
        else{
            return false;
        }
    },

  }
    
}
</script>

<style scoped>
.var{
  font-weight:bold;
  margin-left:17px;
}
.btn{
  width:48%!important;
}

.selectbtn{
margin-left: 130px;
margin-top:-5px! important;
}
.selectbtnm{
margin-left: 105px;
margin-top:-5px! important;
}
.selectbtns{
margin-left: 120px;
margin-top:-5px! important;
}
button .addtocart{
margin:0px ! important;

}


.addquantity input{
  border:none !important;
  width:29px !important;
}
.btnfd{
    border-radius: 7px;
    border: none;
    background-color: #000066;
    color: white;

}
.content-desktop {
     display: block !important;
     }
   #content-mobile {
     display: none ;
     }

  @media screen and (max-width: 500px) {

  .content-desktop {
     display: none !important;
     }
   #content-mobile {
     display: block ;
     }

}
</style>