<template>
<div class="">
    <Disclaimer />
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="page">
      <div class="">
       <div class="banner home-banner content-desktop" style="text-align: center !important; ">
            <div class="container">
            <div class="banner-text">
              <h3 class="title">Let’s take the burden off you. <br> Shop & get it delivered to your doorstep</h3>
              <p class="subtitle">Food, drinks, groceries, and more available for delivery and pickup.</p>

            </div>
          </div> 

           <slider
            class="d-none d-md-block"
            height="400px"
            :ease="0.5"
            :interval="8000"
            :speed="1000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                width="100%"
                 height="100%"
                alt=""
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider> 

          
          <slider
            class="d-block d-md-none"
            :duration="10000"
            height="150px"
            :speed="8000"
            :control-btn="false"
            :indicators="false"
          >
            <slider-item
              v-for="(i, index) in banners"
              :key="index"
              :style="i"
            >
              <img
                :src="image_url+i.img_url"
                alt=""
                width="100%"
                class="router"
                @click="$router.push(i.url.toLowerCase() != '' ? i.url.toLowerCase() :  '/home')"
              >
            </slider-item>
          </slider>
           <div class="content container" style="margin-top: 79px;">
             <div class="product-group  container bg-white my-5"   style= "justify-content: center;">
                  <br>
                  <p><b>Refer  A Customer & Get Rewarded </b></p>
                    <p><b>How it works </b></p>
                    <div style= "justify-content: center;">
                        <div id = "desbox" style="margin-left: 506px;">
                         <img src="../assets/img/referral/register.png" />
                         </div>

                          <div class="row">
                          <div >
                          <img src="../assets/img/referral/arrow.png" />
                          </div>
                          <div  style="margin-left: 480px;">
                           <img src="../assets/img/referral/arrow.png" style="transform: rotate(74deg);" />
                          </div>
                      </div>

                        <div class="row">
                          <div id = "desbox">
                           <img src="../assets/img/referral/refer.png" />
                          </div>
                          <div id = "desbox" style="margin-left: 480px;">
                           <img src="../assets/img/referral/reward.png" />
                          </div>
                      </div>
                   </div>
             </div>
          </div>

           <div class="content container" style="margin-top: 79px;">
             <div class="product-group  container bg-white my-5"   style= "justify-content: center;">
                  <br>

                  <p><b>Register Customer </b></p>
                  <button class="btn" style="margin-top: -69px;width: 214px;margin-right: -860px;"> View Referred Customers</button>

                   <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent='handleSubmit(handleRegister)'>
            <div class="form-row">
              <div class="form-group col-md-12">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    class="form-control"
                    name="title"
                    v-model="user.title"
                  >
                    <option
                      selected
                      hidden
                      value=""
                    >Title</option>
                    <option>Miss</option>
                    <option>Mr</option>
                    <option>Mrs</option>
                    <option>Ms</option>
                  </select>

                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group col-md-6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    type="text"
                    class="form-control"
                    placeholder=" "
                    v-model="user.firstname"
                  >
                  <label
                    class="anim"
                    for=""
                  >First Name</label>
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group col-md-6">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <input
                    placeholder=" "
                    type="text"
                    class="form-control"
                    v-model="user.lastname"
                  >
                  <label
                    class="anim"
                    for=""
                  >Last Name</label>
                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="form-group">
              <validation-provider
                rules="required|email"
                v-slot="{ errors }"
              >
                <input
                  placeholder=" "
                  type="email"
                  class="form-control"
                  v-model="user.email"
                >
                <label
                  class="anim"
                  for=""
                >Email Address</label>
                <span class="err_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="form-group">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
              >
                <vue-tel-input v-model="user.phone"></vue-tel-input>

                <span class="err_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="form-row">
              <div class="form-group col-md-4">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    class="form-control"
                    name="title"
                    v-model="user.gender"
                  >
                    <option
                      selected
                      hidden
                      value=""
                    >Gender</option>
                    <option value="m">Male</option>
                    <option value="f">Female</option>

                  </select>

                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group col-md-4">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    class="form-control"
                    @change="getDob()"
                    v-model="month"
                  >
                    <option
                      selected
                      hidden
                      value=""
                    >Birth Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>

                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="form-group col-md-4">
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                >
                  <select
                    class="form-control"
                    @change="getDob()"
                    v-model="day"
                  >
                    <option
                      selected
                      hidden
                      value=""
                    >Birth Day</option>
                    <option value="01">1</option>
                    <option value="02">2</option>
                    <option value="03">3</option>
                    <option value="04">4</option>
                    <option value="05">5</option>
                    <option value="06">6</option>
                    <option value="07">7</option>
                    <option value="08">8</option>
                    <option value="09">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                  </select>

                  <span class="err_msg">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div
              class="form-group"
              style="position: relative;"
            >
              <validation-provider
                name="password"
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  placeholder=" "
                  class="form-control"
                  v-model="user.password"
                  :type="passwordFieldType"
                >
                <label
                  class="anim"
                  for=""
                >Password</label>
                <span class="err_msg">{{ errors[0] }}</span>
              </validation-provider>
            <!--  <span
                id="show_hide"
                @click="switchVisibility"
              >
                <i
                  v-if="passwordFieldType == 'password'"
                  class="fa fa-eye"
                ></i>
                <i
                  v-if="passwordFieldType == 'text'"
                  class="fa fa-eye-slash"
                ></i>
              </span>-->
            </div>
            <div class="form-group">
              <validation-provider
                name="confirm"
                rules="required|confirmedBy:@password"
                v-slot="
                {
                errors
                }"
              >
                <input
                  placeholder=" "
                  type="password"
                  v-model="confirmpassword"
                  class="form-control"
                >
                <label
                  class="anim"
                  for=""
                >Confirm Password</label>
                <span class="err_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <div class="form-group form-check">

              <input
                type="checkbox"
                class="form-check-input"
                v-model="user.subscribe"
              />
              <label class="form-check-label">
                I want to receive Newsletters with the best deals and offers.
              </label>
            </div>
            <div class="form-group form-check">
              <validation-provider
                rules="required"
                v-slot="{ errors }"
              >
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="terms"
                />

                <label class="form-check-label">I have read and agreed to the
                  <span
                    style="cursor:pointer;"
                    @click="$router.push('terms')"
                  >terms and conditions</span> and <span
                    style="cursor:pointer;"
                    @click="$router.push('privacy')"
                  > Privacy Policy</span> of MarketSquare
                </label>
                <span class="err_msg">{{ errors[0] }}</span>
              </validation-provider>
            </div>
            <button class="msq-button mt-3">sign up</button>
          </form>
        </ValidationObserver>
              </div>
            </div>
        </div>
      </div>
      </div>
    </div>
</div>    
</template>

<script>
import StoreSelector from '@/components/StoreSelector.vue'
import Cart from '@/components/CartComponent.vue'
import TopNav from '@/components/TopNav.vue'
import Tops from '@/components/Tops.vue'
import Footer from '@/components/Footer.vue'
import Disclaimer from '@/components/Disclaimer.vue'
import Questionnaire from "@/components/Questionnaire";
import Product from '@/components/Product.vue'
import ProductModal from '@/components/ProductModal.vue'
import pageDescription from '@/components/pageDescription.vue'
export default {
 name: ' ReferralAgent',
  components: {
      StoreSelector,Cart,TopNav, Tops, Footer, Disclaimer, Questionnaire, Product, ProductModal, pageDescription
  },
   data () {
    return {
            banners: [],
      month: "",
      day: '',
      terms: '',
      confirmpassword: '',
      passwordFieldType: 'password',
      user: {
        title: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        password: "",
        subscribe: false,
        gender: "",
        dob: ""
      },
    }
  },
   watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Refer Customers';
        }
    },
  },
  
  }

</script>
<style>
/*.row{
  justify-content:center;
  margin-top:100px;
}*/
#desbox {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  margin: 10px;
  border-radius: 10px;
  padding: 1px;
  height: 100px;
  background-color: white;
  margin-top: 12px;
  color: rgb(161, 143, 143);

  width: 100px;
}
.form-group{
    text-align:left;
}

#desbox img{
  display: block !important; 
  margin-left: auto !important; 
  margin-right: auto !important; 
  margin-top: 14px !important;
  width: 75px !important;
  
}
</style>