<template>
 <!-- <div :class="isProductPromotion() ? 'col-6 col-md-3 col-lg-3 p-1' : 'col-6 col-md-3 col-lg-2 p-1'"> -->
    <div class="product p-md-2 p-sm-1">

        <p v-if="product.isBlackfriday == 1" style="z-index: 1000;position: absolute;position: absolute;"> 
            <span style="background:#000; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Black Friday Deal</span> 
        </p>

        <div @click="viewProduct(product)" class="product-image" data-target="#foodproduct" data-toggle="modal">
            <img v-if="product.img_url.includes('https://cdn.marketsquareng.website')" v-lazy="product.img_url" alt="" class="img-fluid">
            <img v-else v-lazy="image_url+product.img_url" alt="" class="img-fluid" :style="isProductPromotion() ? 'height:110% !important' : ''">
        </div>

        <div @click="viewProduct(product)" class="product-text" style="margin: auto !important;" data-target="#foodproduct" data-toggle="modal">
           
            <p v-if="product.isOnlineonly == 1" style="margin:1px"> 
                <span style="background:#000066; color:#fff; font-size:11px; border-radius:3px; padding:2px 7px">Online-Only Deal</span> 
            </p>

            <p class="description mb-2">{{product.name}}</p><br>

            <div v-if="isProductPromotion()">
                <p class="description  desc mt-2" style="text-overflow: ellipsis;white-space: nowrap;overflow: hidden;"><b>Content: </b>{{product.description}}</p>
                <p class="description"><a @click.prevent="" style="text-decoration: underline !important; color: #000066;">View Full Content</a></p>
            </div>
        </div>
               
        <div class="product-footer mt-0" style="bottom: 15px;height: auto !important;position: absolute;right: 15px;left: 15px;">       
            <p class="price">
              <span v-if="product.discount">
                    <span v-if="product.variation_weight1> 0" class="pricerange">
                       <span > ₦{{ weightDiscountedPriceSec()}} - ₦{{ weightDiscountedPrice()}}</span><br>
                         <!--  ₦{{product.discount.toLocaleString()}}-->
                           
                             <s style="color:#808080;font-size:10px;" >₦{{product.sellingprice.toLocaleString()}} -  ₦{{ formatPrice(((product.sellingprice * product.variation_weight1) ).toFixed(2)) }}</s>
                            <span style="background-color: #ffcccb; font-size:12px;" class="float-right pr-1 pl-1">
                                {{-product.discount}}%</span>

                            <br>
                            <span v-if="product.variation_weight1> 0">
                                <small style="color:grey; font-size:10px;">( ₦{{ weightDiscountedPriceSec()}} /Kg)</small>
                            
                            </span>
                        
                            <br>
                        <span class="badge badge-info">Online Discount</span>
                    </span>
                   <span v-else>
                   <span >  ₦{{  discountedPrice()}}</span><br>
                    
                     <s style="color:#808080;font-size:10px;">₦{{product.sellingprice.toLocaleString()}} </s><br>

                    <span style="background-color: #ffcccb; font-size:12px;" class="float-right pr-1 pl-1"> {{-product.discount}}%</span>
                    <span class="badge badge-info">Online Discount</span>
                   </span>
                    
             </span>

                <span v-else>
                    <span v-if="product.promo">
                        <span style="color:#808080;font-size:12px;">
                            <s>₦{{ formatPrice(product.sellingprice) }}</s>
                        </span> <br>
                        <span>₦{{ formatPrice(Math.round((product.promo.value_percent/100)*product.sellingprice)) }}</span>

                        <span v-if="product.variation_weight1> 0">
                            <small style="color:grey; font-size:10px;">(₦ {{avgPrice(product.sellingprice, product.avg_weight)}}/Kg)</small>
                        </span>
                    </span>

                    <span v-else-if="product.old_price != null && product.old_price != 'NULL' && product.old_price != '' && Number(product.old_price) > 0">
                        <span v-if="pro.variation_weight1> 0" style="color:#808080;font-size:12px;">
                        <s>₦{{ formatPrice(((product.old_price*product.variation_weight3 ) ).toFixed(2)) }}
                          </s>
                        </span> 
                        <span v-else style="color:#808080;font-size:12px;">
                        <s>₦{{ formatPrice(product.old_price) }}</s>
                        </span> <br>
                        <span  v-if="product.variation_weight1> 0" class ="pricerange">
                           ₦{{ formatPrice(((product.sellingprice * product.variation_weight3)).toFixed(2)) }} - ₦{{ formatPrice(((product.sellingprice *product.variation_weight1 ) ).toFixed(2)) }}
                          
                         </span>
                        <span style="background-color: #ffcccb; font-size:12px;" class="float-right pr-1 pl-1">
                               {{Math.round((( Number(product.sellingprice) - Number(product.old_price))/Number(product.old_price)) * 100)}}%</span>

                        <p v-if="product.variation_weight1> 0">
                            <small style="color:grey; font-size:10px;">(₦ {{avgPrice(product.sellingprice, product.avg_weight)}}/Kg)</small>
                        </p>
                        <span  v-else > ₦{{ formatPrice(product.sellingprice) }}</span>
                    </span>

                    <span  v-else class="price pricerange">
                       <span v-if="product.variation_weight1> 0">
                           
                             ₦{{ formatPrice(((product.sellingprice * product.variation_weight3) ).toFixed(2)) }} - ₦{{ formatPrice(((product.sellingprice *product.variation_weight1 ) ).toFixed(2)) }}
                          
                           
                       </span>
                       <p v-if="product.variation_weight1> 0">
                              <!-- <small style="color:grey; font-size:10px;">(₦ {{avgPrice(product.sellingprice, product.avg_weight)}}/Kg)</small>
                               -->
                               <small style="color:grey; font-size:10px;">(₦ {{ formatPrice(product.sellingprice)}}/Kg)</small>
                           </p>
                       <span v-else>   
                           ₦{{ formatPrice(product.sellingprice) }}

                        
                        </span>
                    </span>
                    
                </span>
            </p>

            <p style="font-size: 10px;color: gray;" v-if="product.variation_weight1> 0">Final cost by weight </p>

            <div v-if="product.variation_weight1> 0">
                <button v-if="foodQuantityCount(product.id) === 0"
                    @click="viewProduct(product)" class="product-text addtocart d-non" style="margin: auto !important; width:125px"             data-target="#foodproduct" data-toggle="modal">
                    <img src="../assets/img/cart.png" class="img1" alt="" >
                    <img class="d-none img2" src="../assets/img/cart-white.png" alt="">
                    <span>Add to cart </span>

                </button>  
            
                <button v-else class="addquantity selectbtn m-0" 
                        :style="{'text-align: left !important' : isProductPromotion()}" data-target="#foodproduct" data-toggle="modal">
                        <div class =" decrease mr-2 ml-1"> - </div>
                        <input
                        
                        oninput="validity.valid||(value='');"
                        :id="'tpf'+index"
                        type="number"
                        min="0.001"
                        step="any"
                        class="number"
                        :value="foodQuantityCount(product.id)">

                        <div  class =" increase mr-2 ml-1"> + </div>
                    
                </button>  
             </div>   
            <!---->
            <div v-else>
            
                <button class="addquantity selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" v-if="isinCart(product.id, 'noKg')">

                <div @click="decreaseQuantity('tpf_modal', product.id, 'noKg')" class="decrease mr-2 ml-1"> - </div>

                <input
                    v-if="product.description.includes('/KG') || product.description.includes('/ KG')"
                    oninput="validity.valid||(value='');"
                    :id="'tpf_modal'"
                    type="number"
                    min="0.001"
                    step="any"
                    class="number"
                    :value="iscartQTY(product.id, 'noKg')"
                    @keypress="restrictChars($event)"
                    @change="inputChange('tpf_modal', product.id, 'noKg')"
                >

                <input
                    v-else
                    :id="'tpf_modal'"
                    type="number"
                    min="0"
                    step="1"
                    class="number"
                    :value="iscartQTY(product.id, 'noKg')"
                    @keypress="restrictChars($event)"
                    @change="inputChange('tpf_modal', product.id, 'noKg')"
                />

                <div @click="increaseQuantity('tpf_modal', product.id, 'noKg')" class="increase ml-2"> + </div>
                </button> 

                <button class="addtocart selectbtn m-0" :style="{'text-align: left !important' : isProductPromotion()}" @click="addTofoodCart(product, 'noKg', 'noKg')" v-else>
                <span>Add To Cart</span>
                </button>
                    
            </div>
          

        </div>
    </div>
 <!-- </div> -->
</template>

<script>
export default {
    name: 'FoodProduct',
    props: ['product', 'index'],
  
    data () {
        return {
        viewproduct: false,
        discoutweight:false,
        image_url: this.$request.url,
        variation:[
        {'title':'Big pack', 'kg':'1.000'},
        {'title':'Medium pack', 'kg':'0.600'},
        {'title':'Small pack', 'kg':'0.400'}
      ],
        pro: '',
        foodcart:[],
        cart: {
            quantity: "",
            unit_price: "",
            price: "",
            product: {
            id: "",
            name: "",
            price: "",
            img_url: ""
            }
        },
        }
    },
    computed: {
      
    },
    created () {
       //function avgPrice(a,b){ return Number((a/b).toFixed(2)).toLocaleString(); };
      this.foodcart = this.$store.getters.foodcart;
      console.log(this.foodcart,"Hello");
     

    },

    methods: {
        // # check qty;
        iscartQTY(id,kg){
        let cart = this.$store.getters.foodcart;
        let qty = 0;
        cart.forEach((i) => {
            if (i.product.id == id && i.weight_type == kg) {
            qty = i.quantity;
            }
        })
        return qty;
        },
        isinCart(id,kg){
            let cart = this.$store.getters.foodcart;
            let isAdded = false;
            cart.forEach((i) => {
                if (i.product.id == id && i.weight_type == kg) {
                isAdded = true;
                }
            })
            return isAdded;
        },
        //add to food cart
        addTofoodCart (product, kg, pack) {


            if (product.promo) {
                product["newprice"] = Math.round( (product.promo.value_percent / 100) * product.sellingprice );
            } 
            else {
                if(kg == 'noKg'){
                product["newprice"] = product.sellingprice; //product.sellingprice;
                }
                else{
                product["newprice"] = (product.sellingprice * kg); //product.sellingprice;
                }
            }
            

            let foodcart = {
                product: {}
            }

            foodcart.quantity = 1;

            foodcart.unit_price = product.newprice;
            foodcart.price = product.newprice * foodcart.quantity;
            foodcart.weight_type = kg;
            foodcart.pack = pack;
            
            foodcart.product.id = product.id;
            foodcart.product.name = product.name;
            foodcart.product.price = product.newprice;
            foodcart.product.img_url = product.img_url;


            let food_cart_array = this.$store.getters.foodcart;
            let check = [];
            
            if (food_cart_array.length > 0) {
                food_cart_array.forEach(i => {
                if (i.product.id == foodcart.product.id && i.weight_type == kg) {
                    check.push(1);
                    i.quantity = parseInt(i.quantity) + 1;
                    i.price += Number(i.unit_price);
                }
                else {
                    check.push(0)
                }
                })
                
                if (!check.includes(1)) {
                food_cart_array.push(foodcart)
                }
            }
            else {
                food_cart_array.push(foodcart)
            }
            this.$store.dispatch('addTofoodCart', food_cart_array);
        },

        foodQuantityCount(product_id){
         let sum = 0;
         let cart = this.foodcart;
         for(let i=0; i<cart.length; i++){
         
            if(product_id === cart[i].product.id){
              sum = sum + cart[i].quantity;
            }
            
            
         } 

          return sum;

             // return product_id;
        },
        avgPrice (a,b){ 
            return Number((a/b).toFixed(2)).toLocaleString(); 
        },

        //*product.variation_weight1 ) / product.avg_weight).toFixed(2))
         discountedPrice (){ 

           let original_price = Number( this.product.sellingprice );
          
           let discount_price = Number(this.product.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price).toFixed(2))

           return Number(final_discount .toFixed(2));
        },
         
        
        //*product.variation_weight1 ) / product.avg_weight).toFixed(2))
         weightDiscountedPrice (){ 

           let original_price = Number( (this.product.sellingprice * this.product.variation_weight1).toFixed(2));
         
           let discount_price = Number(this.product.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price).toFixed(2))

           return Number(final_discount .toFixed(2));
        }, 

         weightDiscountedPriceSec (){ 

           let original_price = Number( (this.product.sellingprice * this.product.variation_weight3).toFixed(2));
         
           let discount_price = Number(this.product.discount)/100;
           let final_discount = Number(original_price - (original_price * discount_price).toFixed(2))

           return Number(final_discount .toFixed(2));
        }, 

        viewProduct (product) {            
             //history.pushState({}, null, `/product/${product.store_id}/${product.category}/${product.name}`);



            let cart = this.$store.getters.foodcart;
            cart.forEach(j => {
                if (product.id == j.product.id) {
                    product.hidebtn = true;
                    product.hideqty = false;
                    product.cart_qty = j.quantity;
                }
            })
            this.pro = product;

            this.viewproduct = true;
            this.$emit('viewProduct',  this.pro);
        },

        formatPrice (price) {
            var str = price.toString().split(".");
            if (str[0].length >= 3) {
                str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            }
            if (!str[1]) {
                str[1] = "00";
            }
            return str.join(".");
        },


        // remove under codes
        addToCart (product, addid, addbtn, id) {
            document.getElementById(addid).classList.remove('hideqty');
            document.getElementById(addbtn).classList.add('hideqty')
            // this.loading = true;

            if(product.discount > 0){
                product["newprice"] = product.discount;
            }
            else{
                if (product.promo) {
                    product["newprice"] = Math.round(
                    (product.promo.value_percent / 100) * product.sellingprice
                    );
                } else {
                    product["newprice"] = product.sellingprice;
                }
            }


            let cart = {
                product: {}
            }
            cart.quantity = document.getElementById(id).value;
            cart.unit_price = product.newprice;
            cart.price = product.newprice * cart.quantity;
            cart.product.id = product.id;
            cart.product.name = product.name;
            cart.product.price = product.newprice;
            cart.product.img_url = product.img_url;
            let cart_array = this.$store.getters.cart;
            let check = [];
            // cart_array.push(cart)
            if (cart_array.length > 0) {
                cart_array.forEach(i => {
                if (i.product.id == cart.product.id) {
                    check.push(1);
                    i.quantity = parseInt(i.quantity) + 1;
                    i.price += Number(i.unit_price);
                    document.getElementById(id).value = i.quantity;
                }
                else {
                    check.push(0)
                }
                })
                // console.log(check)
                if (!check.includes(1)) {
                cart_array.push(cart)
                }
            }
            else {
                cart_array.push(cart)
            }
            this.$store.dispatch('addToCart', cart_array)
        },
        
       

         // # fn() to add qty to order;
    increaseQuantity (id, product_id, kg) {
      var el   = document.getElementById(id);
      var v    = Number(el.value) + 1;
      el.value = v;
      this.updateCartQuantity(v, product_id, "+", kg);
    },

    // # fn() to remove qty from order;
    decreaseQuantity (id, product_id, kg) {
        var el    = document.getElementById(id);
        let value = el.value;

        if (value != 0) {
          value = Number(value) - 1;
          if (value == 0) {
            this.removeFromCart(product_id,kg)
          }
          else {
            el.value = value;
          }
        }
        else {
            value = 1;
            el.value = value;
        }

        this.updateCartQuantity(value, product_id, "-", kg);
    },

    // # fn() to query lDB;
    updateCartQuantity (value, product_id, action, kg) {
        let cart_array = this.$store.getters.foodcart;

        cart_array.forEach(i => {
            if (product_id == i.product.id && i.weight_type == kg) {
              if (action == '+') {
                  i.quantity = parseInt(i.quantity) + 1;
                  i.price += parseInt(i.unit_price);
              }
              else if (action == '-') {
                  i.quantity = parseInt(i.quantity) - 1;
                  i.price -= parseInt(i.unit_price);
              }
              else {
                  i.quantity = value;
                  i.price = parseInt(i.unit_price) * value;
              }
            }
        })
        this.$store.dispatch('addTofoodCart', cart_array)
    },

     // # fn() to delete cart item;
    removeFromCart (id,kg) {
        let index;
        let cart = this.$store.getters.foodcart;
        cart.forEach((i, ind) => {
            if (i.product.id == id && i.weight_type == kg) {
              index = ind
            }
        })
        cart.splice(index, 1);
        this.$store.dispatch('addTofoodCart', cart)
    },

    inputChange (id, product_id, kg) {
      var el = document.getElementById(id);
      let value = el.value;

      if (value == 0) {
          this.removeFromCart(product_id, kg);
      }
      else if (Math.sign(value) == '-1') {
          value = 1;
          el.value = 1;
      }
      else {

      }
      this.updateCartQuantity(value, product_id, "input", kg);
    },
        restrictChars: function ($event) {
            if ($event.key !== '-' || $event.code !== 'Minus') {
                return true
            } else {
                $event.preventDefault();
            }
        },

        isProductPromotion (){
            if(this.product.promotion){
                if(this.product.promotion.toLowerCase().includes('combo'))
                    return true;
            }
            else{
                return false;
            }
        },
    }
}
</script>

<style scoped>
.pricerange{
    font-size: 13px !important;
    font-weight: bolder !important;
}
input{
  border:none !important;
  width:29px !important;
}
</style>