var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopNav'),_c('Tops',{staticStyle:{"margin-bottom":"33px"}}),_c('div'),_c('div',{staticClass:"contact page"},[_vm._m(0),_c('div',{staticClass:"content bg-white"},[_c('div',{staticClass:"container py-5"},[_c('div',{staticClass:"form-box py-5"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.sendMessage)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-md-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.firstname),expression:"contact.firstname"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" ","required":""},domProps:{"value":(_vm.contact.firstname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "firstname", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("First Name")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group col-md-6"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.lastname),expression:"contact.lastname"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" ","required":""},domProps:{"value":(_vm.contact.lastname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "lastname", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Last Name")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.email),expression:"contact.email"}],staticClass:"form-control",attrs:{"type":"email","placeholder":" "},domProps:{"value":(_vm.contact.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "email", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Email Address")]),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"required:length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-tel-input',{model:{value:(_vm.contact.phone),callback:function ($$v) {_vm.$set(_vm.contact, "phone", $$v)},expression:"contact.phone"}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.subject),expression:"contact.subject"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" "},domProps:{"value":(_vm.contact.subject)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "subject", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Subject")])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.area),expression:"contact.area"}],staticClass:"form-control",attrs:{"type":"text","placeholder":" "},domProps:{"value":(_vm.contact.area)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "area", $event.target.value)}}}),_c('label',{staticClass:"anim"},[_vm._v("Area & City")])]),_c('div',{staticClass:"form-group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact.message),expression:"contact.message"}],attrs:{"name":"","rows":"10","placeholder":"Message body","maxlength":"255"},domProps:{"value":(_vm.contact.message)},on:{"keyup":function($event){_vm.contact.textcount = _vm.contact.message.length},"keydown":function($event){_vm.contact.textcount = _vm.contact.message.length},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.contact, "message", $event.target.value)}}}),_c('div',{attrs:{"id":"maxlgt","align":"right"}},[_c('small',[_vm._v(_vm._s(0 + _vm.contact.textcount * 1)+"/255 characters")])]),(_vm.contact.textcount == 255)?_c('span',{staticClass:"err_msg",staticStyle:{"top":"-35px","position":"relative"}},[_vm._v(" Maximum character reached ")]):_vm._e(),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('button',{staticClass:" msq-button",attrs:{"type":"submit"}},[_vm._v("Send")])])]}}])})],1)])])]),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"contact-banner"}},[_c('div',{staticClass:"text"},[_c('h3',{staticClass:"banner-title"},[_vm._v("Get In Touch")]),_c('p',{staticClass:"banner-subtitle"},[_vm._v("Drop Us a Message ")])]),_c('div',{staticClass:"banner-image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("../assets/img/envelope.png"),"alt":""}})])])}]

export { render, staticRenderFns }