<template>
    <div class="modal fade" id="filterModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content shadow-sm">
                <div class="modal-header">
                    <h5 class="modal-title color-scope bolder" id="exampleModalLabel">Filter</h5>
                </div>
                <div class="modal-body">
                    <p class="bolder mb-3 mt-5">Select date range</p>

                    <div class="row">
                        <div class="col-12">                                    
                            <input type="date" v-model="start_date" class="form-control border-b" style="height: 55px;">
                        </div>
                        <div class="col-12 mt-3">                                               
                            <input type="date" v-model="end_date" class="form-control border-b" style="height:55px">
                        </div>
                    </div>

                </div>
                  <div class="modal-footer">
                                <button type="button" class="btn text-white bg-scope" data-bs-dismiss="modal" @click="viewStatement()">View Statememt</button>
                            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FilterModal",
};
</script>
<style scoped>
.modal-dialog { max-width: 20%; margin: 0 0 0 80%;}
.modal {z-index: 9999;width: 100%;height: 100%;}
.modal-content {height: 100vh;border-radius: 0;border: none;border-left:1px solid #dee2e6 !important;}
.modal-title {font-size: 19px;}
.modal-header { border-bottom: 1px solid #dee2e638;}
.border-b{border:2px solid #c1c1c1}
</style>