var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopNav'),_c('Tops'),_c('div',{staticClass:"address page bg-white py-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('NewSidebar',{attrs:{"opt_active":_vm.opt_active}}),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"content"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col-sm-9 mt-3 p-0"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.setWalletPin)}}},[_c('div',{staticClass:"form-group",staticStyle:{"position":"relative"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Enter current password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.set_pin.password),expression:"set_pin.password"}],staticClass:"form-control",attrs:{"placeholder":"Enter your current password","type":"password"},domProps:{"value":(_vm.set_pin.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.set_pin, "password", $event.target.value)}}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group",staticStyle:{"position":"relative"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v("Enter new pin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.set_pin.pin1),expression:"set_pin.pin1"}],staticClass:"form-control",attrs:{"placeholder":"Enter New Pin","type":"password","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');","minlength":"4","maxlength":"6","onfocus":"this.placeholder = 'Enter 4 to 6 digits number'","onblur":"this.placeholder = 'Enter New Pin'"},domProps:{"value":(_vm.set_pin.pin1)},on:{"keyup":function($event){_vm.set_pin.pin1 != _vm.set_pin.pin2
                            ? (_vm.err_match =
                                'Enter the same Pin in confirm pin')
                            : (_vm.err_match = '')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.set_pin, "pin1", $event.target.value)}}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"form-group",staticStyle:{"position":"relative"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',[_vm._v("Confirm new pin")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.set_pin.pin2),expression:"set_pin.pin2"}],staticClass:"form-control",attrs:{"label":"","placeholder":"Confirm your Pin","type":"password","oninput":"this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\\..*?)\\..*/g, '$1');","minlength":"4","maxlength":"6"},domProps:{"value":(_vm.set_pin.pin2)},on:{"keyup":function($event){_vm.set_pin.pin1 != _vm.set_pin.pin2
                            ? (_vm.err_match =
                                'The confirm does not match the Pin')
                            : (_vm.err_match = '')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.set_pin, "pin2", $event.target.value)}}}),_c('span',{staticClass:"err_msg"},[_vm._v(_vm._s(errors[0])+" "+_vm._s(_vm.err_match))])]}}],null,true)})],1),_c('button',{staticClass:"msq-button mt-3"},[_vm._v("Set Wallet Pin")])])]}}])})],1),_c('div',{staticClass:"avatar col-3"})])])],1)]),_c('storeSelector')],1),_c('Footer')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"title text-left"},[_c('a',{attrs:{"href":"/newwallet"}},[_c('img',{staticClass:"arr-left",attrs:{"src":require("@/assets/img/back.png"),"width":"13","height":"12"}})]),_vm._v(" Set Wallet Pin")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('small',[_vm._v("Complete the form to set a new wallet pin")])])}]

export { render, staticRenderFns }