<template>

<div class="card-body">
                  <div>
 
    
                   <table style="width:100%; height:309px">
                    

                      <tr>
                       <td id="theader">ID</td>
                       <td>{{ pageInfo.id }}</td>
                     </tr> 
                      
                        <tr>
                       <td id="theader">Order No</td>
                       <td>{{ pageInfo.order_number }}</td>
                     </tr> 
                         
                          <tr>
                       <td id="theader">Transaction Type</td>
                       <td> {{ pageInfo.type }}</td>
                     </tr> 

                      <tr>
                       <td id="theader">Amount Before</td>
                       <td>{{ pageInfo.amount_before }}</td>
                     </tr> 

                      <tr>
                       <td id="theader">Amount</td>
                       <td>{{pageInfo.amount}}</td>
                     </tr> 

                      <tr>
                       <td id="theader">Amount After</td>
                       <td>{{pageInfo.amount_after}}</td>
                     </tr> 

                      <tr>
                       <td id="theader">Channel</td>
                       <td>{{pageInfo.channel}}</td>
                     </tr> 

                          <tr>
                       <td id="theader">Date</td>
                       
                       <td>{{ mydatef (pageInfo.date) }}</td>
                
                      <tr>
                       <td id="theader">Remarks</td>
                       <td>{{pageInfo.remarks}}</td>
                     </tr> 
                   </table>

                </div>
              </div>
</template>
<script>
import VueTableDynamic from 'vue-table-dynamic'
export default {
  name: 'TransactionModal',
  components: {
     VueTableDynamic
  },
      props:{pageInfo: Object},
    data () {
    return {
          walletTransactions: [],
      params: {
        data: [
        ["Sn", 'Id', 'Order Number','Transaction type', 'Amount Before', 'Amount' , 'Amount After','Channel', 'Remarks', 'Date'],
        ],
        header:'column',
        stripe: true,
         //RowWidth: [{row: 0, width: 60},{row: 1, width: 60},{row: 2, width: 60},{row: 3, width: 60},{row: 4, width: 60},{row: 5, width: 60},]
      },
    
    };
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },

    created () {
      console.log("oo");

    this.fetchWalletTransactions();
    let rave = document.createElement("script");
    rave.setAttribute(
      "src",
      // "https://ravesandboxapi.flutterwave.com/flwv3-pug/getpaidx/api/flwpbf-inline.js"
      "https://api.ravepay.co/flwv3-pug/getpaidx/api/flwpbf-inline.js"
    );
    document.head.appendChild(rave);
  },
  watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Your Wallet Page';
        }
    },
  },

  methods: {

     mydatef(x) {
      let formated_date = new Date(x).toGMTString();
      formated_date = formated_date.split(' ').slice(0, 5).join(' ');
      return formated_date;
    },
    fetchWalletTransactions () {
      let req = {
        what: "wallet",
        showLoader: false,
        params: {
          user_id: this.user.id,
          search: this.search
        }
      }
      this.$request.makeGetRequest(req)
        .then(res => {
          let object = [];
          
          this.params.headings =  ['sn', 'id', 'order_number', 'transaction_type','amount_after', 'amount' , 'amount_after','channel', 'remarks', 'date'];
          this.params.counter = 1;
          // this.params.data = ["Sn", 'Id', 'Order Number', 'Amount Before', 'Amount' , 'Amount After','Channel', 'Remarks', 'Date'];

          this.walletTransactions = res.data.data;
          this.walletTransactions.forEach(val => {

            
            object[0] = this.params.counter++;

            this.params.headings.forEach((key) => {
              if(key != "sn")
                object.push(val[key]);

            });

            this.params.data.push(object);

            object = [];

          });
        })
        .catch(err => console.log(err));
    },
    },
  
};
</script>
<style>
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  
}

td {
  border: 1px solid #353535;
  text-align:left;
  
}

#theader {
    width: 40%;
  background-color: #dddddd;
  border: none;
}
td,
th {

  font-size: 12px !important;
  
}
</style>